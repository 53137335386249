
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { decodeToken } from 'jwt-node-decoder';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { environment } from './../../environments/environment';
import * as Sentry from "@sentry/browser";
import * as LogRocket from 'logrocket'; // Import LogRocket module
declare let window: any;

@Injectable()
export class AuthService extends Api {
  private helper = new JwtHelperService();
  public logRocketSessionURL: string;


  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  public signIn(user: string, password: string): Observable<any> {
    const params = JSON.stringify({ user, password });
    const headers = {
      'x-api-key': this.api_key
    };;

    return this.http.post(`${environment.api.uri}auth/login/`, params, { headers: headers });
  }

  public validarConcurso() {
    //Devuelve el id del concurso o false si no hay concurso seleccionado
    return sessionStorage.getItem('concurso') ? sessionStorage.getItem('concurso') : false;
  }

  public getNombreConcurso() {
    return sessionStorage.getItem('nombreConcurso') ? sessionStorage.getItem('nombreConcurso') : '';
  }

  public isLoggedIn(): boolean {
    return this.getAuthorizationToken() && !this.helper.isTokenExpired(this.getAuthorizationToken());
  }

  public getAuthorizationToken(): string{
    return localStorage.getItem('token')||'';
  }

  public getSessionData(token) {
    return decodeToken(token)['data'] ? decodeToken(token)['data'] : {};
  }

  public logOut() {
    localStorage.removeItem('token');
    this.router.navigate(['login']);
  }

  public async identifyUserLOG(user?: string) {

    const token = this.getAuthorizationToken();
    if (!token) return;
    const sessionData = this.getSessionData(token);
    let userData: any = { name: sessionData.nombre || '', id_club: sessionData.idClub || '' };
    if (user && user.includes('@')) userData.email = user;

    // Identify user for error logging systems
    Sentry.setUser({ id: sessionData.idUsuario || '', username: sessionData.nombre || '', email: userData.email || '' });

    // Send logrocket session URL to Sentry
    LogRocket.getSessionURL((url) => {
      this.logRocketSessionURL = url;
      Sentry.setTag('logRocketSession', this.logRocketSessionURL);
    });

    LogRocket.identify(sessionData.idUsuario, {
      name: sessionData.nombre,
      email: userData.email || '',
    });

  }

}
