import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from '../services/print.service';
import { ListaService } from '../services/lista.service';

@Component({
  selector: 'app-print-clases',
  templateUrl: './print-clases.component.html',
  styleUrls: ['./print-clases.component.css']
})
export class PrintClasesComponent implements OnInit {
  private idConcurso: string;
  private documentData: string[];
  public classes;
  public nombreConcurso: string;
  public mostrarInfoCaballo: any;
  public mostrarPaisJinete: any;

  constructor(private route: ActivatedRoute, private printService: PrintService, private listaService: ListaService) {
    this.idConcurso = this.route.snapshot.paramMap.get('idConcurso');
    this.documentData = this.route.snapshot.paramMap.get('classes').split(',');
    this.nombreConcurso = sessionStorage.getItem('nombreConcurso');
    this.mostrarInfoCaballo = this.route.snapshot.paramMap.get('mostrarInfoCaballo');
    this.mostrarPaisJinete = this.route.snapshot.paramMap.get('mostrarPaisJinete');
  }

  ngOnInit() {
    this.classes = this.documentData.map(c => this.listaService.getBinomios(c).toPromise());
    console.log('classes', this.classes);
    Promise.all(this.classes).then(() => setTimeout(() => { this.printService.onDataReady(); console.log(this.classes); }, 500));
  }

  public infoCaballo(binomio): string{
    let datosCaballo = [];
    if(binomio.dateOfBirth){//Calcular la edad en años
        datosCaballo.push(binomio.dateOfBirth);
    }
    if(binomio.gender)
        datosCaballo.push(binomio.gender);
    if(binomio.colorCode)
        datosCaballo.push(binomio.colorCode);
    if(binomio.colorComplement)
        datosCaballo.push(binomio.colorComplement);
    if(binomio.countryOfBirth)
        datosCaballo.push(binomio.countryOfBirth);
    if(binomio.breed)
        datosCaballo.push(binomio.breed);
    if(binomio.sire)
        datosCaballo.push(binomio.sire);
    if(binomio.dam)
        datosCaballo.push(binomio.dam + binomio.sireOfDam);

    var datos = datosCaballo.join(' / ');
    return (datos.length > 10) ? datos.substr(0, 45) + '...' : datos;
  }
}
