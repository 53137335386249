import { Component, OnInit, Directive, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators, Form } from '@angular/forms';
import { ConcursoService } from '../services/concurso.service';
import { Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ResultadoService } from '../services/resultado.service';
import { AppService } from '../services/app.service';

//SERVICES
import { VideoService } from '../services/videos.service';
import { element } from 'protractor';
import { environment } from 'src/environments/environment';

declare var jquery: any;
declare var $: any;

@Component({
  selector: 'videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  public nombreConcurso: string;
  public idConcurso: string | boolean;
  private idUsuario: string;
  public privilegios: number;
  public firstLoaded: boolean;
  public filtroCuentasVideo: string;
  public id_concurso_local: number;
  public idPruebaConcurso: string;
  public binomio_parametro: string;
  public concursos: any;
  public pruebasConcurso: any;
  public binomios_pruebas_concurso: Array<any>;
  public clientes: any;
  public validadorCliente: UntypedFormControl;
  public videosPendientes: number;
  public insistencia: number;
  public videosForSale: boolean;
  private token: string;
  private sessionData: any;

  selectFormControl = new UntypedFormControl('', Validators.required);
  constructor(
    private route: ActivatedRoute,
    private concursoService: ConcursoService,
    private authService: AuthService,
    private videoService: VideoService,
    private router: Router
  ) {
    this.videosForSale = false;
    this.token = '';
    this.idConcurso = '';
    this.idUsuario = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.filtroCuentasVideo = '';
    this.concursos = [];
    this.pruebasConcurso = [];
    this.binomios_pruebas_concurso = [];
    this.clientes = [];
    this.id_concurso_local = 0;
    this.videosPendientes = 0;
    this.idPruebaConcurso = '';
    this.insistencia = 0;
    this.binomio_parametro = '';
    this.validadorCliente = new UntypedFormControl('', []);
    this.firstLoaded = false;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.idPruebaConcurso = this.route.snapshot.paramMap.get('id_prueba')||'';
  }

  ngOnInit() {
    $('#loader').show();
    this.getConcursos();
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso() && (this.privilegios >= 300) || this.idUsuario == '630') {
      this.idConcurso = this.authService.validarConcurso();
      this.getConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }

    this.route.params.subscribe(params => {
       // (+) converts string 'id' to a number
      // In a real app: dispatch action to load the details here.
      this.binomio_parametro = params['id_binomio'];
    });
    this.getPruebasConcurso();

    setInterval(() => {
      this.consultarVideosPendientes()
    }, 30000);
  }

  @ViewChildren('allTheseThings') things: QueryList<any>;

  ngAfterViewInit() {
    this.things.changes.subscribe(t => {
      this.ngForRendred();
    })
  }

  ngForRendred() {

    let new_element = document.querySelector("#row-" + this.binomio_parametro);
    $("#row-" + this.binomio_parametro).css('background-color', '#8AE170');
    if (this.firstLoaded == false) {
      if (new_element) {
        new_element.scrollIntoView({ block: "end", behavior: "smooth" });
        this.firstLoaded = true;
      }
    }

  }

  public getConcursos() {
    this.videoService.getConcursos().subscribe(
      response => {
        this.concursos = response;
        $('#loader').hide();
        if(this.idPruebaConcurso){
          this.getBinomiosPruebasConcurso();
        }
      },
      error => {
        console.log(error);
      }
    )
  }

  public getConcurso() {
    this.concursoService.getConcurso(this.idConcurso).subscribe(
      response => {
        if (!response.error) {
          this.videosForSale = response.concurso.videosForSale == '1';
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $.NotificationApp.send("Error", 'It was not possible to consult the show data.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }


  public getPruebasConcurso() {
    this.binomios_pruebas_concurso = [];

    $("#row-" + this.binomio_parametro).removeClass("danger");
    this.videoService.getPruebasConcurso(this.idConcurso).subscribe(
      response => {
        this.pruebasConcurso = response;
      },
      error => {
        console.log(error);
      }
    )
  }

  public getBinomiosPruebasConcurso(ipc?: string) {
    this.binomios_pruebas_concurso = [];
    if(ipc){
      this.idPruebaConcurso = ipc;
    } else if(!this.idPruebaConcurso){
      return;
    }
    this.videoService.getBinomiosPruebasConcurso(this.idPruebaConcurso).subscribe(
      response => {
        this.binomios_pruebas_concurso = response.map(r => {
          r.bloqueo1 == '1';
          r.bloqueo2 == '1';
          return r;
        });
        this.videosPendientes = 0;
        $('#loader').hide();
      },
      error => {
        console.log(error);
      }
    )
  }

  public consultarVideosPendientes() {
    if(!this.idPruebaConcurso) return;
    this.videoService.getBinomiosPruebasConcurso(this.idPruebaConcurso).subscribe(
      response => {
        const calificados = response.filter(b => b.calificado_uno == '1').length + response.filter(b => b.calificado_dos == '1').length;
        const calificados2 = this.binomios_pruebas_concurso.filter(b => b.calificado_uno == '1').length + this.binomios_pruebas_concurso.filter(b => b.calificado_dos == '1').length;
        this.videosPendientes = calificados - calificados2;
      },
      error => {
        console.log(error);
      }
    )
  }

  public getResponsablesVideos(event: any) {
    this.filtroCuentasVideo = event.target.value;
    if (this.filtroCuentasVideo.length > 2) {
      this.videoService.getResponsablesVideos(this.filtroCuentasVideo).subscribe(
        response => {
          this.clientes = response
        },
        error => {
          console.log(error);
        }
      );
    } else {
      this.clientes = [];
      console.log('Busqueda muy corta');
    }
  }

  /**
   * @param cliente Objeto cliente al cual se le cargará el video.
   * @param binomio Objeto con los datos del binomio sobre el cual se subio el video.
   * @param index Indice del arreglo binomio_pruebas_concurso al que se le asigna un id_cliente.
   *
   * Asigna un cliente a un binomio participante, para cargarle sus videos.
   * Si el video no ha sido registrado como petición, se generará un nuevo registro.
   * Si el video ya es una petición, le asignará el mismo al cliente.
   */
  public setClienteVideo(cliente, binomio, index) {
    this.clientes = [];
    let accion = '';
    let contacto = '';
    /**
    * Validación de contacto, dando preferencia al teléfono. Si no hay un teléfono,pondra el mail
    */
    if (cliente.email == null || cliente.email == '') {
      contacto = cliente.telefono;
    } else {
      contacto = cliente.email;
    }
    if (binomio.id_video == null) {
      accion = 'crear_registro';
      this.videoService.setPeticionNueva(binomio.id_binomio, this.idPruebaConcurso, cliente.id, contacto, accion).subscribe(
        response => {
          $.NotificationApp.send("Hidden", "Assigned to " + cliente.nombre_cliente, 'bottom-right', '#06d5a1', 'success');
          this.binomios_pruebas_concurso[index].id_cliente = cliente.id;
          this.binomios_pruebas_concurso[index].id_video = 'temporal';
        },
        error => {
          $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    } else if (binomio.id_video != null) {
      accion = 'registrar_video';
      this.videoService.setClienteVideo(binomio.id_binomio, this.idPruebaConcurso, cliente.id, contacto, accion).subscribe(
        response => {
          $.NotificationApp.send("Hidden", "Assigned to " + cliente.nombre_cliente, 'bottom-right', '#06d5a1', 'success');
          this.binomios_pruebas_concurso[index].id_cliente = cliente.id;
        },
        error => {
          $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
        }
      );
    }
  }

  /**
   * @param val Objeto con los atributos del cliente.
   */
  public displayResponsableCuenta(val): string {
    return val ? val.nombre_cliente : val;
  }

  public convertString(value) {
    return parseFloat(value)
  }

  public abrirInputFile(idbinomio) {
    $('#video-' + idbinomio).click()
  }

  /**
   * @param binomio ID del binomio al que se le cargo el video.
   * @param prueba ID de la prueba donde participo el binomio.
   * @param fase Fase de la prueba donde se grabo al binomio.
   * @param idCliente ID del cliente al que se le cobrará el video.
   * @param telefono Teléfono de contacto del cliente (puede llegar vacio).
   * @param email Email del cliente (puede llegar vacio).
   * @param index indice en el arreglo binomios_prueba_concurso con el que se
   *              está interactuando.
   *
   * Habilita u oculta el video en la APP.
   */
  public habilitarAcceso(binomio, prueba, fase, idCliente, telefono, email, index) {
    const oculto = $('#video-' + fase + '-' + binomio).is(':checked') ? 0 : 1;
    let msj = '';
    let accion = "habilitarAcceso";
    let contacto = '';
    if (email == null || email == '') {
      (telefono == null || telefono == '') ? contacto = '' : contacto = telefono;
    } else {
      contacto = email;
    }
    this.videoService.habilitarAcceso(prueba, binomio, idCliente, contacto, fase, oculto, accion).subscribe(
      response => {
        if (response.error == 0) {
          if (oculto == 1) {
            $.NotificationApp.send("Hidden", "The customer does not have access to our video :(.", 'bottom-right', '#06d5a1', 'success');
          } else {
            $.NotificationApp.send("Public", "The customer can access this video :).", 'bottom-right', '#06d5a1', 'success');
            this.insistencia = 0;
          }
        } else {
          $.NotificationApp.send("Error", response.msj, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.msj);
        }
      },
      error => {
        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public exclusivoOtomi(prueba, binomio) {
    const acceso = $('#video-otomi-' + binomio).is(':checked') ? 0 : 1;
    let accion = "exclusivoOtomi";
    this.videoService.exclusivoOtomi(binomio, prueba, acceso, accion).subscribe(
      response => {
        if (response.error == 0) {
          if (acceso == 0) {
            $.NotificationApp.send("Exclusive", "This video is visible from any of our apps.", 'bottom-right', '#06d5a1', 'success');
          } else {
            $.NotificationApp.send("Public", "This video is only available from the Otomí app.", 'bottom-right', '#06d5a1', 'success');
          }
        } else {
          $.NotificationApp.send("Error", response.msj, 'bottom-right', '#fa5c7c', 'error');
        }
      },
      error => {
        $.NotificationApp.send("Error", error, 'bottom-right', '#fa5c7c', 'error');
      }
    )
  }

  public fileDropped(id) {
    const video: File = ($("#video-" + id).prop('files').length > 0) ? $("#video-" + id).prop('files')[0] : '';
    if (video.type == 'video/mp4') {
      document.getElementById('dropZone-' + id).className = "dropZone loaded";
      document.getElementById('label-' + id).innerText = video.name + ' ready to upload';
    } else {
      document.getElementById('dropZone-' + id).className = "dropZone error";
      document.getElementById('label-' + id).innerText = 'Wrong filetype, try again.';
    }

  }

  public subirVideo(id_concurso_local, idPrueba, idBimonio, fase, index) {
    //optimizar
    const concurso = id_concurso_local;
    const prueba = idPrueba;
    const binomio = idBimonio;
    const fase_video = fase;
    const video: File = ($("#video-" + idBimonio).prop('files').length > 0) ? $("#video-" + idBimonio).prop('files')[0] : '';
    let data = {};
    if (concurso && prueba && binomio && fase_video && video) {
      if (fase == 1) {
        this.binomios_pruebas_concurso[index].cargando_1 = 1;
      } else if (fase == 2) {
        this.binomios_pruebas_concurso[index].cargando_2 = 1;
      }
      let formData: FormData = new FormData();
      formData.append('concurso', concurso);
      formData.append('id_prueba', prueba);
      formData.append('binomio', binomio);
      formData.append('fase', fase_video);
      formData.append('video', video);
      formData.append('bloqueo', this.videosForSale ? '1' : '0');
      formData.append('accion', 'subirVideoBucket');
      $.ajaxSetup({
        beforeSend: function (xhr) {
          xhr.setRequestHeader('x-api-key', '7cc263a1-a490-4337-8302-151490495e56');
        }
      });
      $.ajax({
        method: "POST",
        url: `${environment.api.uri}videos/upload-video/`,
        contentType: false,
        processData: false,
        dataType: "json",
        data: formData,
        headers: {
          'x-api-key': '7cc263a1-a490-4337-8302-151490495e56'
        },
        success: (r) => {
          if (!r.error) {
            $.NotificationApp.send("Uploaded", "The video has been successfully uploaded.", 'bottom-right', '#06d5a1', 'success');
            if (fase == 1) {
              this.binomios_pruebas_concurso[index].cargando_1 = 2;
              this.binomios_pruebas_concurso[index].video_app = 'cargado';
              this.binomios_pruebas_concurso[index].bloqueo1 = this.videosForSale;
            } else if (fase == 2) {
              this.binomios_pruebas_concurso[index].cargando_2 = 2;
              this.binomios_pruebas_concurso[index].video_app_2 = 'cargado';
              this.binomios_pruebas_concurso[index].bloqueo2 = this.videosForSale;
            }
            this.consultarVideosPendientes();
          } else if (r.error == 1) {
            $.NotificationApp.send("Error", r.msj, 'bottom-right', '#fa5c7c', 'error');
            if (fase == 1) {
              this.binomios_pruebas_concurso[index].cargando_1 = 0;
            } else if (fase == 2) {
              this.binomios_pruebas_concurso[index].cargando_2 = 0;
            }
          } else {
            if (r.replace(/\n/, '') == '{"error":false}') {
              $.NotificationApp.send("Uploaded", "The video has been successfully uploaded.", 'bottom-right', '#06d5a1', 'success');
              document.getElementById('dropZone-' + idBimonio).className = 'dropZone';
              document.getElementById('label-' + idBimonio).innerText = 'File uploaded';
              if (fase == 1) {
                this.binomios_pruebas_concurso[index].cargando_1 = 2;
                this.binomios_pruebas_concurso[index].video_app = 'cargado';
                this.binomios_pruebas_concurso[index].bloqueo1 = this.videosForSale;
              } else if (fase == 2) {
                this.binomios_pruebas_concurso[index].cargando_2 = 2;
                this.binomios_pruebas_concurso[index].video_app_2 = 'cargado';
                this.binomios_pruebas_concurso[index].bloqueo2 = this.videosForSale;
              }
            } else {
              if (fase == 1) {
                this.binomios_pruebas_concurso[index].cargando_1 = 0;
              } else if (fase == 2) {
                this.binomios_pruebas_concurso[index].cargando_2 = 0;
              }
              $.NotificationApp.send("Error", "Unable to upload the video.", 'bottom-right', '#fa5c7c', 'error');

            }
          }
        },
        error: (r) => {
          console.log(r);
          if (fase == 1) {
            this.binomios_pruebas_concurso[index].cargando_1 = 0;
          } else if (fase == 2) {
            this.binomios_pruebas_concurso[index].cargando_2 = 0;
          }
          $.NotificationApp.send("Error", "Unable to upload the video :(.", 'bottom-right', '#fa5c7c', 'error');
        }
      });
    } else {
      $.NotificationApp.send("Attention!", "To continue complete all fields.", 'bottom-right', '#ffbc00', 'warning');
    }
  }
}
