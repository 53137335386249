import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PruebaService } from '../services/prueba.service';
import { ListaService } from '../services/lista.service';
import { ResultadoService } from '../services/resultado.service';
import { AppService } from '../services/app.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-listas-orden-manual',
  templateUrl: './listas-orden-manual.component.html',
  styleUrls: ['./listas-orden-manual.component.css']
})
export class ListasOrdenManualComponent implements OnInit, AfterViewInit {
  public idConcurso: string | boolean;
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  public nombreConcurso: string;
  public pruebas: any[];
  public pruebaSeleccionada: string;
  public binomios: any[];
  public binomiosAsignados: any;
  public form: UntypedFormGroup;

  constructor(private authService: AuthService, private pruebaService: PruebaService, private listaService: ListaService, private router: Router, private resultadoService: ResultadoService, private appService: AppService) {
    this.idConcurso = '';
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.pruebas = [];
    this.pruebaSeleccionada = '';
    this.binomios = [];
    this.binomiosAsignados = [];
    this.form = new UntypedFormGroup({
      entry1: new UntypedFormControl('', [Validators.required]),
      entry2: new UntypedFormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.getPruebas();
  }

  ngAfterViewInit(): void {

  }

  private getPruebas(): void{
    this.pruebaService.getPruebasList(this.idConcurso).subscribe(
      response => {
        if(!response.error){
          this.pruebas = response.pruebas;
          $('#loader').hide();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to query the classes list.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public seleccionarPrueba(e: any): void{
    $('#loader').show();
    this.binomios = [];
    this.binomiosAsignados = [];
    this.pruebaSeleccionada = e.target.value;
    this.form.reset({
      entry1: '',
      entry2: ''
    });
    this.listaService.getBinomios(this.pruebaSeleccionada).subscribe(
      response => {
        if(!response.error){
          this.binomios = response.binomios;
          this.binomiosAsignados = response.binomios.map((b, i) => {
            return {
              entrada: i+1,
              ibpc: '',
              entry: '',
              horse: '',
              rider: '',
              lastname: ''
            };
          });
          $('#loader').hide();
        } else{
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
          $('#loader').hide();
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry list could not be consulted.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public asignarEntry(e: any, i: number): void{
    const entry = e.target.value;
    const index = this.binomios.findIndex(b => b.cucarda == entry);
    if(index > -1){
      this.binomiosAsignados[i].entry = entry;
      this.binomiosAsignados[i].ibpc = this.binomios[index].ibpc;
      this.binomiosAsignados[i].horse = this.binomios[index].caballo;
      this.binomiosAsignados[i].rider = this.binomios[index].nombreJinete;
      this.binomiosAsignados[i].lastname = this.binomios[index].apellidosJinete;
      this.binomios.splice(index, 1);
      //Cambiar entrada en la bd
      this.listaService.guardarLista([this.binomiosAsignados[i]], this.pruebaSeleccionada, '[]').subscribe(
        response => {
          if(!response.error){
            $.NotificationApp.send("Success", 'The entry number has been changed successfully', 'bottom-right', '#0acf97', 'success', 7000);
            $('#entry-'+(i+1)).focus();
          } else{
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          $.NotificationApp.send("Error", 'The entry number has not been saved, please try again.', 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(error);
        }
      );
    } else{
      $.NotificationApp.send("Warning", 'Please enter a valid entry.', 'bottom-right', '#ffbc00', 'warning', 7000);
      e.target.value = '';
    }
  }

  public quitarEntry(index){
    this.binomios.push({
      cucarda: this.binomiosAsignados[index].entry,
      ibpc: this.binomiosAsignados[index].ibpc,
      caballo: this.binomiosAsignados[index].horse,
      nombreJinete: this.binomiosAsignados[index].rider,
      apellidosJinete: this.binomiosAsignados[index].lastname
    });
    this.binomiosAsignados[index].ibpc = '';
    this.binomiosAsignados[index].entry = '';
    this.binomiosAsignados[index].horse = '';
    this.binomiosAsignados[index].rider = '';
    this.binomiosAsignados[index].lastname = '';
  }

  public guardarFirebase(): void{
    //Actualizar listas en la app
    $('#loader').show();
    this.appService.updateShow(this.idConcurso, this.pruebaSeleccionada);
    /*this.resultadoService.guardarFirestore(this.pruebaSeleccionada).subscribe(
      response => {
          if(response.error){
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
              $('#loader').hide();
          }else{
              $.NotificationApp.send("Success","The class has been synchronized", 'bottom-right', '#fa5c7c', 'success');
              $('#loader').hide();
          }
      },
      error => {
          $.NotificationApp.send("Error", 'The app has not been updated.', 'bottom-right', '#fa5c7c', 'error');
          console.log(error);
          $('#loader').hide();
      }
    );*/
  }

  orderSwap(){
    if(this.form.valid){
      const entryNumber1 = this.form.value.entry1;
      const entryNumber2 = this.form.value.entry2;
      const index1 = this.binomiosAsignados.findIndex(b => b.entry == entryNumber1);
      const index2 = this.binomiosAsignados.findIndex(b => b.entry == entryNumber2);
      //TODO: Mensaje de error uno o ambos entries no tienen orden asignado
      if(index1 == -1 || index2 == -1) return;
      const entry1 = this.binomiosAsignados[index1];
      const entry2 = this.binomiosAsignados[index2];
      this.binomiosAsignados[index1] = entry2;
      this.binomiosAsignados[index2] = entry1;
      this.listaService.guardarLista([this.binomiosAsignados[index1], this.binomiosAsignados[index2]], this.pruebaSeleccionada, '[]').subscribe(
        response => {
          if(!response.error){
            $.NotificationApp.send("Success", 'The entry number has been changed successfully', 'bottom-right', '#0acf97', 'success', 7000);
            this.form.reset({
              entry1: '',
              entry2: ''
            });
            //$('#entry-'+(i+1)).focus();
          } else{
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 7000);
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          $.NotificationApp.send("Error", 'The entry number has not been saved, please try again.', 'bottom-right', '#fa5c7c', 'error', 7000);
          console.log(error);
        }
      );
    }
  }
}
