import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class PersonaService extends Api {

  constructor(private http: HttpClient) {
    super();
  }

  public addPersona(persona, idConcurso, idUsuario): Observable<any>{
    const params = JSON.stringify({ persona, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post('https://xz0q23vz66.execute-api.us-west-1.amazonaws.com/Produccion/people/add', params, {headers: headers});
  }

  public getPersonasFiltradas(filtro, idConcurso): Observable<any>{
    const params = JSON.stringify({ filtro, idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post('https://xz0q23vz66.execute-api.us-west-1.amazonaws.com/Produccion/people/query', params, {headers: headers});
  }

  public getFeiInfo(fei): Observable<any>{
    const params = JSON.stringify({ fei });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}personas/fei/`, params, {headers: headers});
  }

  public getUsefData(usef, organization): Observable<any>{
    const params = JSON.stringify({ usef, organization });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}personas/usef/`, params, {headers: headers});
  }

  public getPersons(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`https://xz0q23vz66.execute-api.us-west-1.amazonaws.com/Produccion/people`, params, {headers: headers});

  }

  public getInfo(idPerson): Observable<any>{
    const params = JSON.stringify({ idPerson });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`https://xz0q23vz66.execute-api.us-west-1.amazonaws.com/Produccion/people/info`,params, {headers: headers});
  }

  public editPerson(person, idPerson, idConcurso, idUsuario): Observable<any>{
    const params = JSON.stringify({ person, idPerson, idConcurso, idUsuario });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`https://xz0q23vz66.execute-api.us-west-1.amazonaws.com/Produccion/people/edit`,params, {headers: headers});
  }

  public updateSuspensionsStatus(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}personas/updateSuspensionsStatus/`,params, {headers: headers});
  }

  public updateUsefPeople(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}personas/updateUsefPeople/`,params, {headers: headers});
  }

  public mergePeopleManual(people): Observable<any>{
    const params = JSON.stringify(people);
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}personas/merge-people-manual/`,params, {headers: headers});
  }

  public historialPersona(idPersona): Observable<any>{
    const params = JSON.stringify({ idPersona });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`https://xz0q23vz66.execute-api.us-west-1.amazonaws.com/Produccion/people/historial`,params, {headers: headers});
  }

  public getPersonasDupicadas(idConcurso): Observable<any>{
    const params = JSON.stringify({ idConcurso });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`https://xz0q23vz66.execute-api.us-west-1.amazonaws.com/Produccion/people/duplicadas`, params, {headers: headers});
  }

  public merge(ids, info): Observable<any>{
    const params = JSON.stringify({ ids, info });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.post(`${environment.api.uri}personas/merge/`,params, {headers: headers});
  }

  public getNRHAInfo(nrha): Observable<any>{
    const params = { id: nrha };
    const headers = {
        'x-api-key': this.api_key,
        'Content-Type': 'application/json;charset=UTF-8'
    };

    return this.http.get(`${environment.api.uri}nrha/person/`, {headers, params});
  }

  setDesignerUsef(ipc, designer){
    const params = JSON.stringify({ ipc, designer });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}pruebas/set-designer-usef/`,params, {headers: headers}));
  }

  setJudgeUsef(ipc, judge){
    const params = JSON.stringify({ ipc, judge });
    const headers = {
      'x-api-key': this.api_key,
      'Content-Type': 'application/json;charset=UTF-8'
    };

    return firstValueFrom(this.http.post(`${environment.api.uri}pruebas/set-judge-usef/`,params, {headers: headers}));
  }


}
