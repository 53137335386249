import { Component, OnInit, AfterViewInit, ViewChild, NgZone } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CaballoService } from '../services/caballo.service';
import { ConcursoService } from '../services/concurso.service';
import { EntrenadorService } from '../services/entrenador.service';
import { ResponsablesService } from '../services/responsables.service';
import { ConceptoService } from '../services/concepto.service';
import { EntriesService } from '../services/entries.service';
import { PersonaService } from '../services/persona.service';
import { PaisService } from '../services/pais.service';
import { ChaseService } from '../services/chase.service';
import { PruebaService } from '../services/prueba.service';
import { UsefService } from '../services/usef.service';
import { StripeService } from '../services/stripe.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FeedOrdersService } from '../services/feed-orders.service';
import { COMMA, ENTER, NUMPAD_PERIOD } from '@angular/cdk/keycodes';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProductService } from '../services/product.service';
import { ReportesService } from '../services/reportes.service';
import { AppService } from '../services/app.service';
import { firstValueFrom } from 'rxjs';
declare let $: any;

export interface Entries {
  entry: number,
  horse: string
}
@Component({
  selector: 'app-entries',
  templateUrl: './entries.component.html',
  styleUrls: ['./entries.component.css']
})
export class EntriesComponent implements OnInit, AfterViewInit {
  //Datos sesion
  private idUsuario: string;
  private token: string;
  private sessionData: any;
  public privilegios: number;
  //Show info
  public idConcurso: string | boolean;
  public idClub: string;
  public nombreConcurso: string;
  //Encabezado y pie de pagina
  public header: string;
  public header2: string;
  public footer: string;
  public invoiceFooter: string;
  //Fecha actual
  public date: Date;
  public fecha: string;
  //Entries
  public entryForm: UntypedFormGroup;
  public entries: any[];
  public binomios: any[];
  public newEntryRiders: any[];
  public entrySeleccionado: string;
  public nuevoEntry: string;
  public editEntryForm: UntypedFormGroup;
  public binomioSeleccionado: string;
  //Info Entry Seleccionado
  public entryInfo: EntryInfo | null;
  public suspended: boolean;
  public suspendedReason: string;
  public ushja: boolean;
  public ushjaReason: string;
  public logs: any[];
  public entryNotes: any;
  public usefId: string;
  public nsbaId: string;
  public nrhaId: string;
  public feiId: string;
  public altura: string;
  public chip_id: boolean;
  public chipValid: boolean = false;
  public horseSuspended: boolean = true;
  public horseSuspendedReason: string = '';
  public horseMembership: boolean = true;
  public searchEntry: string;
  //Variables de control cuenta seleccionada
  public pantalla: number; //0 Crear entries, 1 Cuenta entry
  public cargando: boolean;
  public displayedColumns: string[] = ['Entry', 'Horse', 'Search'];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, NUMPAD_PERIOD, 190];
  public editarNotas: boolean;
  //Anular Multa
  public ibpcAAnular: string;
  public motivo: UntypedFormControl;
  public mensajeMotivo: string;
  //Horse Info
  public caballos: any[];
  public idCaballo: string;
  public nombreCaballo: string;
  public caballoParametro: string;
  public addHorse: boolean;
  public panelHorse: boolean;
  public horseForm: UntypedFormGroup;
  //Personas
  public personForm: UntypedFormGroup;
  public organization: boolean;
  public tipoPersona: string;
  public personSuspensionData: any = {};
  public searchPerson: string;
  public addPersonaEntity: string;
  //Rider Info
  public jinetes: any[];
  public addRider: boolean;
  public panelRider: boolean;
  public editRiderTrainerForm: UntypedFormGroup;
  public addRiderTrainerForm: UntypedFormGroup;
  public ibpcSwapSeleccionado: string;//Rider swap
  public formRiderSwap: UntypedFormGroup;
  public riderId: string;
  //Payee Info
  public responsables: any[];
  public nombrePayee: string;
  public addPayee: boolean;
  public panelPayee: boolean;
  public payeeId: string;
  public payeeAddress: string;
  public payeeAddress2: string;
  //Owner Info
  public owners: any[];
  public usefOwners: any[];
  public ownerId: string;
  public ownerLikeUsef: boolean;
  public ownerUsefId: string;
  public addOwner: boolean;
  public panelOwner: boolean;
  public changeOwnerForm: UntypedFormGroup;
  public nombreOwner: string;
  public ownerAddress: string;
  public ownerAddress2: string;
  public ownerEmail: string;
  public ownerUsef: string;
  public owner: any = { usef: false, ushja: false, suspension: true, reason: '', usefType: '-', ushjaType: '-' };// USEF Owner Validation
  //Trainer Info
  public entrenadores: any[];
  public entrenadoresEntry: any[];
  public addTrainer: boolean;
  public panelTrainer: boolean;
  public changeTrainerForm: UntypedFormGroup;
  public trainer: any = { usef: false, ushja: false, suspension: true, reason: '', usefType: '-', ushjaType: '-' };// USEF Trainer Validation
  public trainerId: string;
  public trainerEmail: string;
  public trainerName: string;
  //Inscripciones
  public pruebas: any[];
  public pruebasRepetidas: any[];
  public ridersClasses: any;
  public inscritosChip: any;
  public extemporaneos: any;
  public scratcheados: any;
  public inscritos: any;
  public totalInscripciones: number;
  public pruebasFiltradas: any[];
  //Cargos
  public formCargo: UntypedFormGroup;
  public cargos: any[];
  public cargosInscripciones: any[];
  public conceptos: any[];
  public nominaciones: any[];
  public totalCargos: number;
  public totalesFeiFee: any[];
  public charges: Promise<any>;
  public cargosSeleccionados: any[];
  public idConceptoComision: string;
  //Splits
  public splits: any[];
  public totalSplits: number;
  //Pagos
  public formPago: UntypedFormGroup;
  public pagos: any[];
  public totalPagos: number;
  public numeroCheque: string;
  public paymentDetails: any;
  //Premios
  public premios: any[];
  public premiosAplicados: any[];
  public premiosChampionshipSeries: any[];
  public totalPremios: number;
  public totalPremiosAplicados: number;
  public totalPremiosChampionshipSeries: number;
  public aplicarPremios: boolean;
  //Feed Orders
  public summaryOrders: any[];
  //Paises
  public paises: any[];
  public queryPais: string;
  //Traspaso
  public formTraspaso: UntypedFormGroup;
  public concursos: any[];
  public entriesTraspaso: any[];
  public entrenadoresTraspaso: any[];
  public motivoAnulacionTraspaso: string;
  public idTransaccionAnulacionTraspaso: string;
  public montoAnulacionTraspaso: string;
  public filterBalanceTransferEntries: string;
  public get trainersBalanceTransfer(): any[] {
    return (this.entrenadoresTraspaso||[]).filter(t => (this.filterBalanceTransferEntries && ((t.nombre||'').toLowerCase().includes(this.filterBalanceTransferEntries.toLowerCase()) || (t.usefId||'').toLowerCase().includes(this.filterBalanceTransferEntries.toLowerCase()))) || !this.filterBalanceTransferEntries);
  }
  //Merch
  public products: any[];
  public selectedProducts: any[];
  public selectedProduct: string;
  public selectedQty: number;
  public selectedTotal: number;
  //Resultados
  public resultados: any[];
  public championshipSeries: boolean;
  //Tarjetas
  public cardForm: UntypedFormGroup;
  public errorTarjeta: string;
  public cards: any[];
  public validated: boolean;
  public notasTarjeta: string;
  public retryTrace: string;
  public showAddCardForm: boolean;
  public tarjetaSeleccionada: string;
  public newCard: boolean;
  //Stripe
  public useStripe: boolean;
  public stripeCustomers: StripeCustomer[];
  public emails: any[];
  public stripeMetadata: any;
  public stripeDescription: string;
  public stripeFormVisible: boolean;
  public totalPayment: number;
  public defaultCardHolder: string;
  //100x/Reining
  public reining: boolean;
  //Pagination
  private _current: number;
  public get current(): number {
    return this._current;
  }
  public set current(value: number) {
    this.getEntriesPaginated(value);
    this._current = value;
  }
  public total: number;
  public totalItems: number;

  constructor(
    private authService: AuthService,
    private caballoService: CaballoService,
    private concursoService: ConcursoService,
    private entrenadorService: EntrenadorService,
    private responsablesService: ResponsablesService,
    private conceptoService: ConceptoService,
    private entriesService: EntriesService,
    private personaService: PersonaService,
    private paisService: PaisService,
    private chaseService: ChaseService,
    private pruebaService: PruebaService,
    private router: Router,
    private route: ActivatedRoute,
    private feedOrdersService: FeedOrdersService,
    private ngZone: NgZone,
    private db: AngularFirestore,
    private productService: ProductService,
    private usefService: UsefService,
    private stripeService: StripeService,
    private reportesService: ReportesService,
    private appService: AppService
  ) {
    this.idConcurso = this.authService.validarConcurso();
    this.idUsuario = '';
    this.token = '';
    this.sessionData = {};
    this.privilegios = 0;
    this.nombreConcurso = this.authService.getNombreConcurso();
    this.caballos = [];
    this.entrySeleccionado = '';
    this.nuevoEntry = '';
    this.header = '';
    this.header2 = '';
    this.footer = '';
    this.nombreCaballo = '';
    this.nombrePayee = '';
    this.date = new Date();
    this.binomios = [];
    this.totalInscripciones = 0;
    this.totalCargos = 0;
    this.cargos = [];
    this.cargosInscripciones = [];
    this.nominaciones = [];
    this.totalesFeiFee = [];
    this.splits = [];
    this.totalSplits = 0;
    this.totalPagos = 0;
    this.pagos = [];
    this.premios = [];
    this.totalPremios = 0;
    this.premiosAplicados = [];
    this.totalPremiosAplicados = 0;
    this.invoiceFooter = '';
    this.ibpcAAnular = '';
    this.motivo = new UntypedFormControl('', [Validators.required]);
    this.mensajeMotivo = '';
    this.formCargo = new UntypedFormGroup({
      concepto: new UntypedFormControl('', [Validators.required]),
      notas: new UntypedFormControl('', []),
      cantidad: new UntypedFormControl('', [Validators.required]),
      monto: new UntypedFormControl({ value: '', disabled: false }, [Validators.required])
    });
    this.conceptos = [];
    this.fecha = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getUTCDate()).padStart(2, '0')}`;
    this.formPago = new UntypedFormGroup({
      metodo: new UntypedFormControl('', [Validators.required]),
      referencia: new UntypedFormControl('', []),
      fecha: new UntypedFormControl(this.fecha, [Validators.required]),
      monto: new UntypedFormControl('', [Validators.required])
    });
    this.caballoParametro = '';
    this.pantalla = 0;
    this.entries = [];
    this.entryForm = new UntypedFormGroup({
      entry: new UntypedFormControl('', [Validators.required]),
      horse: new UntypedFormControl('', [Validators.required]),
      owner: new UntypedFormControl('', [Validators.required]),
      payee: new UntypedFormControl('', [Validators.required]),
      rider: new UntypedFormControl('', []),
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.responsables = [];
    this.jinetes = [];
    this.entrenadores = [];
    this.addRider = false;
    this.addPayee = false;
    this.addTrainer = false;
    this.addHorse = false;
    this.addOwner = false;
    this.panelRider = false;
    this.panelPayee = false;
    this.panelTrainer = false;
    this.panelOwner = false;
    this.panelHorse = false;
    this.horseForm = new UntypedFormGroup({
      fei: new UntypedFormControl('', []),
      usef: new UntypedFormControl('', []),
      ushja: new UntypedFormControl('', []),
      nsba: new UntypedFormControl('', []),
      nrha: new UntypedFormControl('', []),
      name: new UntypedFormControl('', [Validators.required]),
      chip: new UntypedFormControl('', []),
      owner: new UntypedFormControl('', []),
      ownerUsef: new UntypedFormControl('', []),
      country: new UntypedFormControl('', []),
      color: new UntypedFormControl('', []),
      colorComplement: new UntypedFormControl('', []),
      breed: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      dateOfBirth: new UntypedFormControl('', []),
      sire: new UntypedFormControl('', []),
      dam: new UntypedFormControl('', []),
      suspensionStatus: new UntypedFormControl('', []),
      height: new UntypedFormControl('', []),
      height_category: new UntypedFormControl('', []),
      notes: new UntypedFormControl('', [])
    });
    this.personForm = new UntypedFormGroup({
      fei: new UntypedFormControl('', []),
      usef: new UntypedFormControl('', []),
      ushja: new UntypedFormControl('', []),
      nsba: new UntypedFormControl('', []),
      nrha: new UntypedFormControl('', []),
      name: new UntypedFormControl('', [Validators.required]),
      lastname: new UntypedFormControl('', []),
      maternalLastname: new UntypedFormControl('', []),
      notas: new UntypedFormControl('', []),
      amateurProStatus: new UntypedFormControl('', []),
      w9: new UntypedFormControl('', []),
      email: new UntypedFormControl('', []),
      age: new UntypedFormControl('', []),
      nationality: new UntypedFormControl('', []),
      dateOfBirth: new UntypedFormControl('', []),
      telephone: new UntypedFormControl('', []),
      cellPhone: new UntypedFormControl('', []),
      gender: new UntypedFormControl('', []),
      /*country: new FormControl('', []),
      state: new FormControl('', []),
      city: new FormControl('', []),
      address: new FormControl('', []),
      address2: new FormControl('', []),
      zip: new FormControl('', []),*/
      usefAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      taxAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      mailAddress: new UntypedFormGroup({
        country: new UntypedFormControl('', []),
        state: new UntypedFormControl('', []),
        city: new UntypedFormControl('', []),
        address: new UntypedFormControl('', []),
        address2: new UntypedFormControl('', []),
        zip: new UntypedFormControl('', []),
      }),
      taxId: new UntypedFormControl('', []),
      taxName: new UntypedFormControl('', []),
      suspensionStatus: new UntypedFormControl('', []),
      amateur: new UntypedFormControl(false, []),
      stripeCustomerId: new UntypedFormControl('', []),
    });
    this.paises = [];
    this.queryPais = '';
    this.cardForm = new UntypedFormGroup({
      number: new UntypedFormControl('', [Validators.required, Validators.minLength(18)/*, Validators.maxLength(19)*/]),
      date: new UntypedFormControl('', [Validators.required, Validators.minLength(7)]),
      name: new UntypedFormControl('', []),
      save: new UntypedFormControl(false, [])
    });
    this.errorTarjeta = '';
    this.editEntryForm = new UntypedFormGroup({
      horse: new UntypedFormControl('', [Validators.required]),
      payee: new UntypedFormControl('', [Validators.required])
    });
    this.editRiderTrainerForm = new UntypedFormGroup({
      rider: new UntypedFormControl('', [Validators.required]),
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.changeOwnerForm = new UntypedFormGroup({
      owner: new UntypedFormControl('', [Validators.required])
    });
    this.changeTrainerForm = new UntypedFormGroup({
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.addRiderTrainerForm = new UntypedFormGroup({
      rider: new UntypedFormControl('', [Validators.required]),
      trainer: new UntypedFormControl('', [Validators.required])
    });
    this.binomioSeleccionado = '';
    this.pruebas = [];
    this.extemporaneos = {};
    this.scratcheados = {};
    this.inscritos = {};
    this.organization = false;
    this.nombreOwner = '';
    this.suspended = false;
    this.suspendedReason = '';
    this.ushja = false;
    this.ushjaReason = '';
    this.ownerAddress = '';
    this.ownerAddress2 = '';
    this.ownerEmail = '';
    this.ownerUsef = '';
    this.payeeAddress = '';
    this.payeeAddress2 = '';
    this.ibpcSwapSeleccionado = '';
    this.formRiderSwap = new UntypedFormGroup({
      rider: new UntypedFormControl('', [Validators.required])
    });
    this.summaryOrders = [];
    this.logs = [];
    this.owners = [];
    this.formTraspaso = new UntypedFormGroup({
      concurso: new UntypedFormControl('', [Validators.required]),
      entry: new UntypedFormControl('', []),
      entrenador: new UntypedFormControl('', []),
      fecha: new UntypedFormControl(this.fecha, [Validators.required]),
      notas: new UntypedFormControl('', []),
      monto: new UntypedFormControl('', [Validators.required]),
      deEntry: new UntypedFormControl('', [Validators.required]),
      deConcurso: new UntypedFormControl(this.idConcurso, [Validators.required]),
      deEntrenador: new UntypedFormControl('', []),
      idUsuario: new UntypedFormControl(this.idUsuario, [Validators.required])
    });
    this.concursos = [];
    this.entriesTraspaso = [];
    this.entrenadoresTraspaso = [];
    this.pruebasRepetidas = [];
    this.inscritosChip = [];
    this.cards = [];
    this.tarjetaSeleccionada = '';
    this.validated = false;
    this.entryNotes = '';
    this.motivoAnulacionTraspaso = '';
    this.idTransaccionAnulacionTraspaso = '';
    this.montoAnulacionTraspaso = '';
    this.selectedProducts = [];
    this.selectedProduct = '';
    this.selectedQty = 1;
    this.selectedTotal = 0;
    this.ownerId = '';
    this.pruebasFiltradas = [];
    this.usefId = '';
    this.nsbaId = '';
    this.feiId = '';
    this.altura = '';
    this.chip_id = false;
    this.payeeId = '';
    this.trainerId = '';
    this.riderId = '';
    this.tipoPersona = '';
    this.cargosSeleccionados = [];
    this.notasTarjeta = '';
    this.displayedColumns = ['Entry', 'Horse', 'Search'];
    this.retryTrace = '';
    this.searchEntry = '';
    this.searchPerson = '';
    this.newEntryRiders = [];
    this.addPersonaEntity = '';
    this.numeroCheque = '';
    this.newCard = false;
    this.stripeCustomers = [];
    this.trainerEmail = '';
    this.trainerName = '';
    this.aplicarPremios = false;
    this.editarNotas = false;
    this.resultados = [];
    this.idConceptoComision = '';
    this.championshipSeries = false;
    this.premiosChampionshipSeries = [];
    this.totalPremiosChampionshipSeries = 0;
    this.showAddCardForm = false;
    this.idClub = '';
    this.emails = [];
    this.reining = sessionStorage.getItem('reining') == '1';
    this.filterBalanceTransferEntries = '';
    this.paymentDetails = {};
    this.useStripe = false;
    this.stripeMetadata = {};
    this.stripeDescription = '';
    this.stripeFormVisible = false;
    this.totalPayment = 0;
    this.defaultCardHolder = '';
    this.entryInfo = null;
    this.total = 0;
    this.totalItems = 0;
    this.current = 1;
  }

  @ViewChild('classList') classList: MatChipList;

  async ngOnInit() {
    if (!this.authService.isLoggedIn()) {
      this.authService.logOut();
      return;
    } else {
      this.token = this.authService.getAuthorizationToken();
      this.sessionData = this.authService.getSessionData(this.token);
      this.idUsuario = String(this.sessionData['idUsuario']);
      this.privilegios = this.sessionData.privilegios;
    }
    if (this.authService.validarConcurso()) {
      this.idConcurso = this.authService.validarConcurso();
    } else {
      this.router.navigate(['']);
      return;
    }
    this.personForm.get('nationality').valueChanges.subscribe(data => {
      this.queryPais = data;
    });
    this.getConcurso();
    this.getConcursos();
    this.getPaises();
    this.getConceptos();
    this.getPruebas();
    this.entrySeleccionado = this.route.snapshot.params.entry||'';
    this.getProducts();
  }

  ngAfterViewInit() {
    //Shortcuts
    document.addEventListener('keydown', (e) => {
      if(e.ctrlKey && e.key == 'l'){
        e.preventDefault();
        this.showAddEntry();
      } else if(e.key == 'PageUp' && this.entrySeleccionado){
        e.preventDefault();
        const index = this.entries.findIndex(e => e.entry == this.entrySeleccionado);
        if(this.entries[index - 1]) {
          this.ngZone.run(() => {
            this.selectEntry(this.entries[index - 1].entry);
          });
        }
      } else if(e.key == 'PageDown' && this.entrySeleccionado){
        e.preventDefault();
        const index = this.entries.findIndex(e => e.entry == this.entrySeleccionado);
        if (this.entries[index + 1]) {
          this.ngZone.run(() => {
            this.selectEntry(this.entries[index + 1].entry);
          });
        }
      }
    }, false);
    //Listeners
    this.listenerModalInscripcionesCerrado();
    $('#cardPaymentStripeModal').on('hide.bs.modal', (e) => {
      this.stripeFormVisible = false;
    });
    //Al cargar componente preseleccionar el campo de entry en automatico
    $('#input-entry').focus();
    //Plugin Typeahead
    $("#entryTraspaso").typeahead({
      hint: !0,
      highlight: !0,
      minLength: 1
    },
    {
      name: 'entryTraspaso',
      display: value => `${value.entry} - ${value.horse}`,
      templates: {
        notFound: '<div class="empty-message">No matches.</div>',
        suggestion: data => {
          return `<div>${data.entry} - ${data.horse}</div>`;
        }
      },
      source: (this.entriesTraspaso,
        (e, a) => {
          const substrRegex = new RegExp(e, "i");
          const t = this.entriesTraspaso.filter(tm => substrRegex.test(tm.entry) || substrRegex.test(tm.horse));
          a(t.map(tm => tm));
      }
    )
    });
    $('#entryTraspaso').bind('typeahead:select', (ev, suggestion) => {
      this.formTraspaso.get('entry').setValue(suggestion.entry);
    });
    //Plugin Select 2
    $('#select-tarjeta').on('select2:select', (e) => {
      this.tarjetaSeleccionada = e.target.value || '';
    });
  }

  public applyFilter(filterValue: string) {
    // filterValue = filterValue.trim(); // Remove whitespace
    // filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    // this.dataSource.filterPredicate = (data: Entries, filter: string): boolean => {
    //   const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
    //     return (currentTerm + (data as { [key: string]: any })[key] + '◬');
    //   }, '').normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    //   const transformedFilter = filter.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    //   return dataStr.indexOf(transformedFilter) != -1;
    // }
    // this.dataSource.filter = filterValue;
    console.log('Filtering by entry', filterValue);
    // Wait for typing to finish

    if(filterValue.length > 2){
      return firstValueFrom(this.entriesService.searchEntries(this.idConcurso, filterValue)).then(
      response => {
        if (!response.error) {
          this.entries = response.data;
          this.total = Math.ceil(this.entries.length / 10);//10: Items per page
          this.totalItems = this.entries.length;
          //this.dataSource.data = response.data;
          /*if (this.entrySeleccionado) {
            this.selectEntry(this.entrySeleccionado);
          } else {*/
            $('#loader').hide();
          //}
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        return response;
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the entry list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return error;
      }
    )} else if (filterValue == ''){
      this.current = 1;
    };
  }

  public async searchEntryTraspaso(searchString: any) {
      this.entriesTraspaso = await firstValueFrom(this.entriesService.searchEntries(this.formTraspaso.value.concurso||this.idConcurso, searchString||'')).then(r => r.data||[]);
      const theVal = $('#entryTraspaso').val();
      $("#entryTraspaso").typeahead('val', '')
      $("#entryTraspaso").focus().typeahead('val',theVal).focus();
      //$("#entryTraspaso").trigger("input");
      return this.entriesTraspaso;
  }

  /*private async getEntries() {
    return firstValueFrom(this.entriesService.getEntries(this.idConcurso)).then(
      response => {
        if (!response.error) {
          this.entries = response.data;
          if (this.entrySeleccionado) {
            this.selectEntry(this.entrySeleccionado);
          } else {
            $('#loader').hide();
          }
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        return response;
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "It has not been possible to query the entry list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return error;
      }
    );
  }*/

  public async getPaises() {
    return firstValueFrom(this.paisService.getPaises()).then(
      response => {
        if (!response.error) {
          this.paises = response.paises;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        return response;
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the countries list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return error;
      }
    );
  }

  private async getConceptos() {
    return firstValueFrom(this.conceptoService.getConceptos(this.idConcurso)).then(
      response => {
        if (!response.error) {
          this.conceptos = response.conceptos.filter(c => !c.feed);
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        return response;
      },
      error => {
        $.NotificationApp.send("Error", "It has not been possible to query the charges list.", 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return error;
      }
    );
  }

  public async getConcurso() {
    return firstValueFrom(this.concursoService.getConcurso(this.idConcurso)).then(
      response => {
        if (!response.error) {
          this.aplicarPremios = response.concurso.aplicar_premios;
          this.header = response.concurso.header;
          this.header2 = response.concurso.header2;
          this.footer = response.concurso.footer;
          this.idConceptoComision = response.concurso.idConceptoComision;
          this.idClub = response.concurso.club;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        return response;
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the show info.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return error;
      }
    );
  }

  public async getConcursos() {
    return firstValueFrom(this.concursoService.getConcursos(this.idConcurso)).then(
      response => {
        if (!response.error) {
          this.concursos = response.consursos;
        } else {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
        return response;
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to consult the show list', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return error;
      }
    );
  }

  public async getPruebas() {
    return firstValueFrom(this.pruebaService.getPruebas(this.idConcurso)).then(
      response => {
        this.pruebas = response;
        return response;
      },
      error => {
        $.NotificationApp.send("Error", 'It has not been possible to query the classes.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
        return error;
      }
    );
  }

  public valorAbsoluto(num: number): number {
    return Math.abs(num);
  }

  public toggleDetalleCargoCaballo(index) {
    this.cargos[index].mostrarDetalle = !this.cargos[index].mostrarDetalle;
  }

  public showDetalleCargoCaballo(index) {
    this.cargos[index].mostrarDetalle = true;
  }

  public modalAnularMulta(ibpc) {
    this.ibpcAAnular = ibpc;
    //Limpiar modal
    this.motivo.reset();
    this.motivo.setValue('');
    $('#modal-anular-multa').modal('show');
  }

  public async anularMulta() {
    if (this.motivo.valid) {
      const motivo = this.motivo.value;
      $('#loader').show(); // Mostrar loader antes de la solicitud
      try {
        const response = await firstValueFrom(this.responsablesService.anularMulta(this.ibpcAAnular, motivo, this.idUsuario, this.idConcurso));
        if (!response.error) {
          this.selectEntry(this.entrySeleccionado);
          $.NotificationApp.send("Saved", "The penalty fee has been canceled.", 'bottom-right', '#06d5a1', 'success');
          $('#modal-anular-multa').modal('hide');
        } else {
          this.handleError(response.message, 'It has not been possible to cancel the penalty fee..');
        }
      } catch (error) {
        this.handleError(error, 'It has not been possible to cancel the penalty fee.');
      }
    }
  }

  public mostrarMotivo(motivo) {
    this.mensajeMotivo = motivo;
    $('#motivoAnularMulta').modal('show');
  }

  public calcularCargo() {
    if (this.formCargo.value.concepto && this.formCargo.value.cantidad) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.formCargo.value.concepto;
      });
      this.formCargo.get('monto').setValue(parseFloat(this.conceptos[index].monto) * this.formCargo.value.cantidad);
    } else if (this.formCargo.value.concepto && !this.formCargo.value.cantidad) {
      const index = this.conceptos.findIndex(concepto => {
        return concepto.id == this.formCargo.value.concepto;
      });
      this.formCargo.get('cantidad').setValue(1);
      this.formCargo.get('monto').setValue(parseFloat(this.conceptos[index].monto));
    }
  }

  public async agregarCargo() {
    this.formCargo.get('concepto').markAsTouched();
    this.formCargo.get('cantidad').markAsTouched();
    this.formCargo.get('monto').markAsTouched();
    if (this.formCargo.valid) {
      const datos = {
        concepto: this.formCargo.get('concepto').value,
        cantidad: this.formCargo.get('cantidad').value,
        monto: this.formCargo.get('monto').value,
        notas: this.formCargo.get('notas').value,
        entry: this.entrySeleccionado,
        idUsuario: this.idUsuario,
        idConcurso: this.idConcurso
      };
      try {
        const response = await firstValueFrom(this.entriesService.addCharge(datos));
        if (!response.error) {
          this.selectEntry(this.entrySeleccionado);
          $.NotificationApp.send("Saved", response.message, 'bottom-right', '#06d5a1', 'success');
        } else {
          this.handleError(response.message, "Error adding charge.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to add the charge.");
      }
    }
  }

  public async agregarPago() {
    this.formPago.get('metodo').markAsTouched();
    this.formPago.get('fecha').markAsTouched();
    this.formPago.get('monto').markAsTouched();
    if (this.formPago.valid) {
      //Validar si metodo de pago es tarjeta
      if (this.formPago.get('metodo').value == 'Tc'){
        if (this.useStripe) {
          //Validar si ya tiene tarjetas guardadas
          this.stripeMetadata = { entry: this.entrySeleccionado, user: this.idUsuario, show: this.idConcurso };
          this.stripeDescription = `Show: ${this.nombreConcurso}, Entry: ${this.entrySeleccionado}, Horse: ${this.nombreCaballo}`;
          const total = parseFloat(this.formPago.value.monto||'0');
          if(total <= 0){
            $.NotificationApp.send("Error", "The payment amount must be greater than 0.", 'bottom-right', '#fa5c7c', 'error');
            return;
          }
          this.totalPayment = total;
          this.defaultCardHolder = this.trainerName||'';
          this.stripeFormVisible = true;
          //Mostrar modal stripe
          $('#cardPaymentStripeModal').modal('show');
          return;
        } else {
          //Chase bank
          this.cardForm.reset();
          this.cardForm.setValue({
            number: '',
            date: '',
            name: '',
            save: false
          });
          this.errorTarjeta = '';
          $('#select-tarjeta').val('').trigger('change');
          this.tarjetaSeleccionada = '';
          this.notasTarjeta = this.formPago.get('referencia').value;
          this.retryTrace = this.makeid(16);
          $('#cardPaymentModal').modal('show')
          return;
        }
      }
      if (this.formPago.get('metodo').value == 'Cheque') {
        this.numeroCheque = '';
        $('#numero-cheque').val('');
        $('#checkPaymentModal').modal('show');
        setTimeout(() => {
          $('#numero-cheque').focus();
        }, 400);
        return;
      }
      this.addPayment();
    }
  }

  public async addPayment(stripe?: { pi: string, comision: number }) {
    if (this.formPago.get('metodo').value == 'Cheque') {
      if (this.numeroCheque != '') {
        let referencia = this.formPago.get('referencia').value ? this.formPago.get('referencia').value + '. Check number: ' + this.numeroCheque : 'Check number: ' + this.numeroCheque;
        this.formPago.get('referencia').setValue(referencia);
      } else {
        $('#numero-cheque').addClass('no-valido');
        return;
      }
    }

    //Monto pago
    let amount = parseFloat(this.formPago.value.monto||'0');
    //Referencia
    let reference = this.formPago.value.referencia||'';
    const comisiones = [];

    //Si es pago con tarjeta se agrega la comision al total y se crea transaccion de comision
    if(this.formPago.value.metodo == 'Tc' && stripe){//Stripe
      if(stripe.pi){
        reference = [`Payment ID: ${stripe.pi}`, `${reference || ''}`].filter(v => v).join(', ');
      }
      if(stripe.comision){
        amount = this.agregarComision(this.formPago.value.monto, stripe.comision);
        //Agregar cargo de comision de tarjeta
        comisiones.push({
          cantidad: 1,
          monto: this.agregarComision(this.formPago.value.monto, stripe.comision, true),
          entry: this.entrySeleccionado,
          notas: `Payment ID: ${stripe.pi||''}`,
          concepto: null,
          idConcurso: this.idConcurso,
          idUsuario: this.idUsuario
        });
      }
    } else if(this.formPago.value.metodo == 'Tc'){//Chase
      amount = this.agregarComision(this.formPago.value.monto, .03);
      //TODO: Agregar referencia de pago con tarjeta chase
      //En chase la transaccion del pago se crea en el backend
    }

    const pago = {
      metodo: this.formPago.value.metodo,
      referencia: reference||'',
      fecha: this.formPago.value.fecha,
      monto: amount,
      entry: this.entrySeleccionado,
      idUsuario: this.idUsuario,
      idConcurso: this.idConcurso
    };

    //Guardar pago en mysql
    const response = await this.entriesService.addPayment(pago, comisiones);
    if(response.error){
      console.log(response.message);
      $('#checkPaymentModal').modal('hide');
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }

    $('#checkPaymentModal').modal('hide');
    this.selectEntry(this.entrySeleccionado);
    $.NotificationApp.send("Saved", response.message, 'bottom-right', '#06d5a1', 'success');
  }

  public checkNumberPayment(e) {
    this.numeroCheque = e.currentTarget.value;
    if (this.numeroCheque == '')
      $('#numero-cheque').addClass('no-valido');
    else
      $('#numero-cheque').removeClass('no-valido');
  }

  public imprimir() {
    this.date = new Date();
    window.print();

  }

  public async anularPago(idTransaccion, monto, metodo) {
    if (metodo == 'Traspaso') {
      $('#motivoAnulacionTraspaso').modal('show');
      this.motivoAnulacionTraspaso = '';
      this.idTransaccionAnulacionTraspaso = idTransaccion;
      this.montoAnulacionTraspaso = monto;
      return;
    }
    $('#loader').show();
    try {
      const response = await firstValueFrom(this.entriesService.cancelPayment(idTransaccion, metodo, monto, this.idUsuario, this.idConcurso, this.entrySeleccionado));

      if (!response.error) {
        this.selectEntry(this.entrySeleccionado);
        $.NotificationApp.send("Canceled", response.message, 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message, "Error canceling payment.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to cancel the payment.");
    }
  }

  public async eliminarCargo(idTransaccion, monto) {
    try {
      const response = await firstValueFrom(this.entriesService.cancelCharge(idTransaccion, monto, this.idUsuario, this.idConcurso, this.entrySeleccionado));
      if (!response.error) {
        await this.getEntryInfo();
        const [chargesResponse, ordersResponse] = await Promise.all([
          this.entriesService.getCharges(this.entrySeleccionado, this.idConcurso).toPromise(),
          this.feedOrdersService.entrySummary(this.idConcurso, this.entrySeleccionado).toPromise()
        ]);

        this.cargos = chargesResponse.cargos;
        this.cargosInscripciones = chargesResponse.cargosInscripciones;
        this.totalCargos = chargesResponse.totalCargos;
        this.summaryOrders = ordersResponse.summary;

        $.NotificationApp.send("Charge removed", response.message, 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message, "Error removing charge.");
      }
    } catch (error) {
      this.handleError(error, "It was not possible to remove this charge.");
    }
  }

  public async getCaballos(e) {
    const filtro: string = e.target.value;
    if (filtro.length > 2) {
      try {
        const response = await firstValueFrom(this.caballoService.getCaballosFiltrados(filtro, this.idConcurso));

        if (!response.error) {
          this.caballos = response.caballos;
          this.addHorse = this.caballos.length === 0;
        } else {
          this.handleError(response.message);
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to query the horses, please try again.");
      }
    } else {
      this.caballos = [];
      this.addHorse = false;
    }
  }

  public displayCaballo(idCaballo): string {
    const caballo = this.caballos.find(c => c.id_caballo == idCaballo);
    return caballo ? caballo.fullname : idCaballo;
  }

  public async getPersonas(e, entidad) {
    const filtro: string = e.target.value; //Solo hacer consulta de personas cuando la cadena tiene mas de 3 letras
    this.filterBalanceTransferEntries = filtro;
    if (filtro.length > 3) {
      try {
        const response = await firstValueFrom(this.personaService.getPersonasFiltradas(filtro, this.idConcurso));
        if (!response.error) {
          switch (entidad) {
            case 'payee':
              this.responsables = response.personas;
              this.addPayee = (this.responsables.length === 0);
              break;
            case 'rider':
              this.jinetes = response.personas;
              this.addRider = (this.jinetes.length === 0);
              break;
            case 'trainer':
              this.entrenadores = response.personas;
              this.addTrainer = (this.entrenadores.length === 0);
              break;
            case 'owner':
              this.owners = response.personas;
              this.addOwner = (this.owners.length === 0);
              break;
          }
        } else {
          this.handleError(response.message);
        }
      } catch (error) {
        switch (entidad) {
          case 'payee':
            this.handleError(error, 'It has not been possible to query the payees, please try again.');
            break;
          case 'rider':
            this.handleError(error, 'It has not been possible to query the riders, please try again.');
            break;
          case 'trainer':
            this.handleError(error, 'It has not been possible to query the trainers, please try again.');
            break;
          case 'owner':
            this.handleError(error, 'It has not been possible to query the owners, please try again.');
            break;
        }
      }
    } else {
      switch (entidad) {
        case 'payee':
          this.responsables = [];
          this.addPayee = false;
          break;
        case 'rider':
          this.jinetes = [];
          this.addRider = false;
          break;
        case 'trainer':
          this.entrenadores = [];
          this.addTrainer = false;
          break;
        case 'owner':
          this.owners = [];
          this.addOwner = false;
          break;
      }
    }
  }

  public displayResponsable(idPersona): string {
    const responsable = this.responsables.find(p => p.id_persona == idPersona);
    return responsable ? responsable.fullname : idPersona;
  }

  public displayJinete(idPersona): string {
    const jinete = this.jinetes.find(p => p.id_persona == idPersona);
    return jinete ? jinete.fullname : idPersona;
  }

  public displayEntrenador(idPersona): string {
    const entrenador = this.entrenadores.find(p => p.id_persona == idPersona);
    return entrenador ? entrenador.fullname : idPersona;
  }

  public displayOwner(idPersona): string {
    const owner = this.owners.find(p => p.id_persona == idPersona);
    return owner ? owner.fullname : idPersona;
  }

  public displayPais(idPais): string {
    const pais = this.paises.find(p => p.id == idPais);

    return pais ? pais.name : idPais;
  }

  public async addEntry(e) {
    //Validar que el valor de caballo sea tomado desde la lista
    const indexCaballo = this.caballos.findIndex(c => c.id_caballo == this.entryForm.get('horse').value);
    if (indexCaballo == -1) {
      this.entryForm.get('horse').setValue('');
    }
    //Validar que el valor de responsable sea tomado desde la lista
    const indexResponsable = this.responsables.findIndex(p => p.id_persona == this.entryForm.get('payee').value);
    if (indexResponsable == -1) {
      this.entryForm.get('payee').setValue('');
    }
    //Validar que el valor de entrenador sea tomado desde la lista
    const indexEntrenador = this.entrenadores.findIndex(p => p.id_persona == this.entryForm.get('trainer').value);
    if (indexEntrenador == -1) {
      this.entryForm.get('trainer').setValue('');
    }
    //Validar que el valor de owner sea tomado desde la lista
    const indexOwner = this.owners.findIndex(o => o.id_persona == this.entryForm.get('owner').value);
    if (indexOwner == -1) {
      this.entryForm.get('owner').setValue('');
    }
    //Validar que formulario este completo
    if (this.entryForm.valid && this.newEntryRiders.length > 0) {
      $('#loader').show();
      let index = 0;
      for (const r of this.newEntryRiders) {
        this.entryForm.get('rider').setValue(r.id);
        if (index == 0) {
          index = index + 1;
          await this.entriesService.addEntry(this.entryForm.value, this.idConcurso, this.idUsuario).toPromise().then(
            response => {
              if (!response.error) {
                e.target.classList.remove('was-validated');
                //Actualizar info usef del caballo y personas relacionadas al agregar o editar un entry
                this.selectEntry(this.entryForm.value.entry);
                this.reportesService.updateSuspendedMembershipHorses(this.idConcurso, [this.entryForm.value.horse]).toPromise();
                this.reportesService.updateSuspendedMembershipPeople(this.idConcurso, [this.entryForm.value.owner, this.entryForm.value.rider, this.entryForm.value.owner]).toPromise();

                this.entryForm.setValue({
                  entry: '',
                  horse: '',
                  owner: '',
                  payee: '',
                  rider: '',
                  trainer: ''
                });
                this.caballos = [];
                this.owners = [];
                this.responsables = [];
                this.newEntryRiders = [];
                this.entrenadores = [];
                $('#loader').hide();
                $.NotificationApp.send("Success", "The entry has been saved successfully.", 'bottom-right', '#0acf97', 'success');
              } else {
                $('#loader').hide();
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
                index = this.newEntryRiders.length + 1; // terminar ciclo
              }
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", "It has not been possible to add the entry.", 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
            }
          );
        } else if (index <= this.newEntryRiders.length) {
          index = index + 1;
          // SI hay mas de un jinete .....
          await this.entriesService.addCombination(r.id, this.entryForm.get('trainer').value, this.entryForm.get('horse').value, this.entryForm.get('payee').value, this.entryForm.get('owner').value, this.entryForm.get('entry').value, this.idConcurso).toPromise().then(
            response => {
              if (!response.error) {
              } else {
                $('#loader').hide();
                $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
                console.log(response.message);
              }
            },
            error => {
              $('#loader').hide();
              $.NotificationApp.send("Error", "It has not been possible to add the combination.", 'bottom-right', '#fa5c7c', 'error');
              console.log(error);
            }
          );
        }
      };

    } else {
      $('#loader').hide();
      $.NotificationApp.send("Error", 'Please fill the form correctly.', 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public riderSelected(e): void {
    const index = this.jinetes.findIndex(p => p.id_persona == e);
    if (index != -1) {
      this.newEntryRiders.push({ 'id': e, 'fullname': this.jinetes[index].fullname, 'usef': this.jinetes[index].usef, 'fei': this.jinetes[index].fei });
      this.entryForm.get('rider').setValue('');
    }
  }

  public removeRider(id): void {
    const index = this.newEntryRiders.findIndex(r => r.id == id);
    this.newEntryRiders.splice(index, 1);
  }

  public mostrarPanelPersona(entidad) {
    this.organization = false;
    this.panelRider = false;
    this.panelPayee = false;
    this.panelTrainer = false;
    this.panelOwner = false;
    this.panelHorse = false;

    $('#personForm').removeClass('was-validated');
    //Limpiar formulario al abrir
    this.personForm.setValue({
      fei: '',
      usef: '',
      ushja: '',
      nsba: '',
      nrha: '',
      name: '',
      lastname: '',
      maternalLastname: '',
      notas: '',
      amateurProStatus: '',
      w9: '',
      email: '',
      age: '',
      nationality: '',
      dateOfBirth: '',
      telephone: '',
      cellPhone: '',
      gender: '',
      /*country: '',
      state: '',
      city: '',
      address: '',
      address2: '',
      zip: '',*/
      usefAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      mailAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxId: '',
      taxName: '',
      suspensionStatus: '',
      amateur: false,
      stripeCustomerId: ''
    });

    this.organization = false;

    switch (entidad) {
      case 'rider':
        this.personForm.patchValue({
          name: this.entryForm.value.rider
        });
        this.panelRider = true;
        this.ownerId = '';
        this.payeeId = '';
        this.trainerId = '';
        this.riderId = '';
        break;
      case 'payee':
        this.personForm.patchValue({
          name: this.entryForm.value.payee
        });
        this.panelPayee = true;
        this.ownerId = '';
        this.payeeId = '';
        this.trainerId = '';
        this.riderId = '';
        break;
      case 'trainer':
        this.personForm.patchValue({
          name: this.entryForm.value.trainer
        });
        this.panelTrainer = true;
        this.ownerId = '';
        this.payeeId = '';
        this.trainerId = '';
        this.riderId = '';
        break;
      case 'owner':
        this.personForm.patchValue({
          name: this.entryForm.value.owner
        });
        this.panelOwner = true;
        this.ownerId = '';
        this.payeeId = '';
        this.trainerId = '';
        this.riderId = '';
        break;
    }
  }

  public mostrarAddPerson(entidad) {
    $('#personForm').removeClass('was-validated');
    //Limpiar formulario al abrir
    this.personForm.setValue({
      fei: '',
      usef: '',
      ushja: '',
      nsba: '',
      nrha: '',
      name: '',
      lastname: '',
      maternalLastname: '',
      notas: '',
      amateurProStatus: '',
      w9: '',
      email: '',
      age: '',
      nationality: '',
      dateOfBirth: '',
      telephone: '',
      cellPhone: '',
      gender: '',
      /*country: '',
      state: '',
      city: '',
      address: '',
      address2: '',
      zip: '',*/
      usefAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      mailAddress: {
        country: '',
        state: '',
        city: '',
        address: '',
        address2: '',
        zip: '',
      },
      taxId: '',
      taxName: '',
      suspensionStatus: '',
      amateur: false,
      stripeCustomerId: ''
    });

    this.organization = false;
    this.addPersonaEntity = entidad;
    switch (entidad) {
      case 'rider':
        this.personForm.patchValue({
          name: this.editRiderTrainerForm.value.rider
        });
        break;
      case 'payee':
        this.personForm.patchValue({
          name: this.editEntryForm.value.payee
        });
        break;
      case 'trainer':
        this.personForm.patchValue({
          name: this.changeTrainerForm.value.trainer
        });
        this.personForm.patchValue({
          name: this.editRiderTrainerForm.value.trainer
        });
        break;
      case 'owner':
        this.personForm.patchValue({
          name: this.changeOwnerForm.value.owner
        });
        break;
    }
    $('#editOwner').modal('show');
  }

  public mostrarAddHorse() {
    $('#horseForm2').removeClass('was-validated');
    this.idCaballo = '';
    this.horseForm.setValue({
      fei: '',
      usef: '',
      ushja: '',
      nsba: '',
      nrha: '',
      name: '',
      chip: '',
      owner: '',
      ownerUsef: '',
      country: '',
      color: '',
      colorComplement: '',
      breed: '',
      gender: '',
      dateOfBirth: '',
      sire: '',
      dam: '',
      suspensionStatus: '',
      height: '',
      height_category: '',
      notes: ''
    });
    this.horseForm.get('name').setValue(this.editEntryForm.get('horse').value);
    $('#editHorse').modal('show');
  }

  public mostrarPanelHorse() {
    $('#horseForm').removeClass('was-validated');
    this.caballos = [];

    //Limpar formulario al abrir
    this.horseForm.setValue({
      fei: '',
      usef: '',
      ushja: '',
      nsba: '',
      nrha: '',
      name: this.entryForm.value.horse,
      chip: '',
      owner: '',
      ownerUsef: '',
      country: '',
      color: '',
      colorComplement: '',
      breed: '',
      gender: '',
      dateOfBirth: '',
      sire: '',
      dam: '',
      suspensionStatus: '',
      height: '',
      height_category: '',
      notes: ''
    });
    this.panelRider = false;
    this.panelPayee = false;
    this.panelTrainer = false;
    this.panelOwner = false;
    this.panelHorse = true;
    this.ownerId = '';
    this.payeeId = '';
    this.trainerId = '';
    this.riderId = '';
    this.idCaballo = '';
  }

  public cerrarPaneles() {
    this.panelRider = false;
    this.panelPayee = false;
    this.panelTrainer = false;
    this.panelOwner = false;
    this.panelHorse = false;

    $('#horseForm').removeClass('was-validated');
  }

  public async getHorseFeiInfo() {
    const fei = this.horseForm.get('fei').value.toUpperCase();
    if (fei) {
      $('#loader-fei').show();
      try {
        const response = await firstValueFrom(this.caballoService.getFeiInfo(fei));
        if (!response.error) {
          $('#loader-fei').hide();
          const caballo = response.caballo;
          this.horseForm.get('name').setValue(caballo.name ?? this.horseForm.get('name').value);
          this.horseForm.get('chip').setValue(caballo.chipId ?? this.horseForm.get('chip').value);
          this.horseForm.get('country').setValue(caballo.countryOfOrigin ?? this.horseForm.get('country').value);
          this.horseForm.get('color').setValue(caballo.color ?? this.horseForm.get('color').value);
          this.horseForm.get('breed').setValue(caballo.breed ?? this.horseForm.get('breed').value);
          this.horseForm.get('gender').setValue(caballo.gender ?? this.horseForm.get('gender').value);
          this.horseForm.get('dateOfBirth').setValue(caballo.dateOfBirth ?? this.horseForm.get('dateOfBirth').value);
          this.horseForm.get('sire').setValue(caballo.sire ?? this.horseForm.get('sire').value);
          this.horseForm.get('dam').setValue(caballo.dam ?? this.horseForm.get('dam').value);
          this.horseForm.get('owner').setValue(caballo.owner ?? this.horseForm.get('owner').value);
        } else {
          $('#loader-fei').hide();
          this.handleError(response.message, "Error getting FEI information.");
        }
      } catch (error) {
        $('#loader-fei').hide();
        this.handleError(error, "It has not been possible to perform the query.");
      }
    } else {
      $.NotificationApp.send("Invalid FEI.", "The FEI number is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
    }
  }

  public async getHorseUsefData() {
    const usef = this.horseForm.get('usef').value;
    if (usef) {
      $('#loader-usef').show();
      try {
        const response = await firstValueFrom(this.caballoService.getUsefData(usef, this.idConcurso));
        if (!response.error) {
          $('#loader-usef').hide();
          const caballo = response.usefData;
          this.horseForm.get('name').setValue(caballo.name ?? this.horseForm.get('name').value);
          this.horseForm.get('chip').setValue(caballo.chip ?? this.horseForm.get('chip').value);
          this.horseForm.get('country').setValue(caballo.countryOfOrigin ?? this.horseForm.get('country').value);
          this.horseForm.get('color').setValue(caballo.color ?? this.horseForm.get('color').value);
          this.horseForm.get('breed').setValue(caballo.breed ?? this.horseForm.get('breed').value);
          this.horseForm.get('gender').setValue(caballo.gender ?? this.horseForm.get('gender').value);
          this.horseForm.get('dateOfBirth').setValue(caballo.dateofBirth ?? this.horseForm.get('dateOfBirth').value);
          this.horseForm.get('sire').setValue(caballo.sire ?? this.horseForm.get('sire').value);
          this.horseForm.get('dam').setValue(caballo.dam ?? this.horseForm.get('dam').value);
          this.owners = [{
            id_persona: caballo.owner.id,
            fullname: caballo.owner.name
          }];
          this.horseForm.get('owner').setValue(caballo.owner.id ?? this.horseForm.get('owner').value);
          this.horseForm.get('fei').setValue(caballo.fei ?? this.horseForm.get('fei').value);
          this.horseForm.get('height').setValue(caballo.height ?? this.horseForm.get('height').value);
          this.horseForm.get('suspensionStatus').setValue(caballo.suspensionStatus ?? this.horseForm.get('suspensionStatus').value);
        } else {
          $('#loader-usef').hide();
          this.handleError(response.message);
        }
      } catch (error) {
        $('#loader-usef').hide();
        this.handleError(error, "It has not been possible to perform the query.");
      }
    } else {
      $.NotificationApp.send("Invalid Usef ID.", "The Usef ID is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
    }
  }

  public async agregarCaballo() {
    //Validar que el valor de owner sea tomado desde la lista
    const indexOwner = this.owners.findIndex(p => p.id_persona == this.horseForm.get('owner').value);
    if (indexOwner == -1) {
      this.horseForm.get('owner').setValue('');
    }

    if(this.horseForm.valid) {
      $('#loader').show();
      //se hace stringify y parse para que no se haga un bind entre las variables
      let caballo = JSON.parse(JSON.stringify(this.horseForm.value));
      caballo.dateOfBirth = caballo.dateOfBirth.length == 10 ? caballo.dateOfBirth : '';
      caballo.ownerUsef = caballo.ownerUsef ? caballo.ownerUsef.split(',').map(usef => { return { usefId: !isNaN(parseInt(usef.trim())) ? parseInt(usef.trim()) : 0 } }) : [];
      if (!this.idCaballo) {
        try {
          const response = await firstValueFrom(this.caballoService.addCaballo(caballo, this.idConcurso, this.idUsuario));
          if (!response.error) {
            $('#loader').hide();
            this.caballos = [response.caballo];
            this.entryForm.get('horse').setValue(response.caballo.id_caballo);
            this.editEntryForm.get('horse').setValue(response.caballo.id_caballo)
            if (caballo.owner) {
              this.entryForm.get('owner').setValue(caballo.owner);
              this.responsables = this.owners;
              this.entryForm.get('payee').setValue(caballo.owner);
            }
            this.addHorse = false;
            $('#editHorse').modal('hide');
            this.cerrarPaneles();
          } else {
            this.handleError(response.message, "Error adding horse.");
          }
        } catch (error) { this.handleError(error, "It has not been possible to add the horse."); }
      } else {
        firstValueFrom(this.caballoService.editHorse(this.horseForm.value, this.idCaballo, this.idConcurso, this.idUsuario)).then(
          response => {
            if (!response.error) {
              this.getEntryInfo();
              this.getRidersClasses();
              $('#editHorse').modal('hide');
              $('#loader').hide();
            } else {
              $('#loader').hide();
              $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
              console.log(response.message);
            }
          }).catch(error => {
            $('#loader').hide();
            console.log(error);
            $.NotificationApp.send("Error", "It has not been possible to update the horse.", 'bottom-right', '#fa5c7c', 'error');
          });
      }
    }
  }

  public async getPersonFeiInfo() {
    const fei = this.personForm.value.fei;
    if (fei) {
      $('#loader-fei').show();
      try {
        const response = await firstValueFrom(this.personaService.getFeiInfo(fei));
        if (!response.error) {
          $('#loader-fei').hide();
            const persona = response.persona;
            //Verificar si el pais de nacimiento esta en el lista de paises
            const countryOfBirth = this.paises.find(p => p.acronym == persona.countryOfBirth) ? this.paises.find(p => p.acronym == persona.countryOfBirth)['id'] : '';
            this.personForm.patchValue({
              name: persona.name || this.personForm.value.name,
              lastname: persona.lastname || this.personForm.value.lastname,
              nationality: countryOfBirth || this.personForm.value.nationality,
              dateOfBirth: persona.dateOfBirth || this.personForm.value.dateOfBirth,
              gender: persona.gender || this.personForm.value.gender,
            });
        } else {
          this.handleError(response.message, "Error getting FEI information.");
          $('#loader-fei').hide();
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to perform the query.");
      }
    } else {
      $.NotificationApp.send("Invalid FEI.", "The FEI number is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
    }
  }

  public async getPersonUsefData() {
    const usef = this.personForm.value.usef;
    if (usef) {
      $('#loader-usef').show();
      try {
        const response = await firstValueFrom(this.personaService.getUsefData(usef, this.organization));
        if (!response.error) {
          $('#loader-usef').hide();
          const persona = response.usefData;
          this.personForm.patchValue({
            name: persona.name || this.personForm.value.name,
            lastname: persona.lastname || this.personForm.value.lastname,
            email: persona.email || this.personForm.value.email,
            age: persona.age || this.personForm.value.age,
            fei: persona.fei || this.personForm.value.fei,
            telephone: persona.telephone || this.personForm.value.telephone,
            dateOfBirth: persona.dateofBirth || this.personForm.value.dateOfBirth,
            gender: persona.gender || this.personForm.value.gender,
            amateurProStatus: persona.amateurProStatus || this.personForm.value.amateurProStatus,
            usefAddress: {
              country: persona.country || this.personForm.value.country,
              state: persona.state || this.personForm.value.state,
              city: persona.city || this.personForm.value.city,
              address: persona.address || this.personForm.value.address,
              address2: persona.address2 || this.personForm.value.address2,
              zip: persona.zip || this.personForm.value.zip,
            },
            suspensionStatus: persona.suspensionStatus || this.personForm.value.suspensionStatus,
          });
        } else {
          $('#loader-usef').hide();
          this.handleError(response.message);
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to perform the query.");
      }
    } else {
      $.NotificationApp.send("Invalid Usef ID.", "The Usef ID is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
    }
  }

  public async agregarPersona() {
    //Validar que el valor de pais sea tomado desde la lista
    const indexPais = this.paises.findIndex(p => p.id == this.personForm.value.nationality);
    if (indexPais == -1) {
      this.personForm.patchValue({
        nationality: ''
      });
    }
    if (this.personForm.valid) {
      $('#loader').show();
      //se hace stringify y parse para que no se haga un bind entre las variables
      let persona = {
        ...this.personForm.value,
        address: this.personForm.value.usefAddress.address,
        address2: this.personForm.value.usefAddress.address2,
        country: this.personForm.value.usefAddress.country,
        city: this.personForm.value.usefAddress.city,
        state: this.personForm.value.usefAddress.state,
        zip: this.personForm.value.usefAddress.zip,
      };
      persona.dateOfBirth = persona.dateOfBirth.length == 10 ? persona.dateOfBirth : '';
      persona.entity_type = this.organization ? 'O' : 'I';
      if ((!this.ownerId && !this.payeeId && !this.trainerId) || this.addPersonaEntity != '') {
        try {
          const response = await firstValueFrom(this.personaService.addPersona(persona, this.idConcurso, this.idUsuario));
          if (!response.error) {
            $('#loader').hide();
            if (this.panelRider || this.addPersonaEntity == 'rider') {
              this.jinetes.push(response.persona);
              this.entryForm.get('rider').setValue(response.persona.id_persona);
              this.editRiderTrainerForm.get('rider').setValue(response.persona.id_persona);
              this.addRider = false;
            } else if (this.panelPayee || this.addPersonaEntity == 'payee') {
              this.responsables.push(response.persona);
              this.entryForm.get('payee').setValue(response.persona.id_persona);
              this.editEntryForm.get('payee').setValue(response.persona.id_persona);
              this.addPayee = false;
            } else if (this.panelTrainer || this.addPersonaEntity == 'trainer') {
              this.entrenadores.push(response.persona);
              this.entryForm.get('trainer').setValue(response.persona.id_persona);
              this.editRiderTrainerForm.get('trainer').setValue(response.persona.id_persona);
              this.changeTrainerForm.get('trainer').setValue(response.persona.id_persona);
              this.addTrainer = false;
            } else if (this.panelOwner || this.addPersonaEntity == 'owner') {
              this.owners.push(response.persona);
              this.responsables.push(response.persona);
              this.entryForm.get('owner').setValue(response.persona.id_persona);
              this.entryForm.get('payee').setValue(response.persona.id_persona);
              this.changeOwnerForm.get('owner').setValue(response.persona.id_persona);
              this.addOwner = false;
            }
            this.addPersonaEntity = '';
            this.cerrarPaneles();
            $('#editOwner').modal('hide');
            $.NotificationApp.send("Success", "The person has been added successfully.", 'bottom-right', '#06d5a1', 'success');
          } else {
            this.handleError(response.message);
          }
        } catch (error) {
          this.handleError(error, "It has not been possible to add the person.");
        }
      } else {
        let personId = '';
        switch (this.tipoPersona) {
          case 'p':
            personId = this.payeeId;
            break;
          case 't':
            personId = this.trainerId;
            break;
          case 'o':
            personId = this.ownerId;
            break;
          case 'r':
            personId = this.riderId;
            break;
          default:
            break;
        }
        try {
          const response = await firstValueFrom(this.personaService.editPerson(persona, personId, this.idConcurso, this.idUsuario));
          if (!response.error) {
            this.getEntryInfo();
            this.riderId = '';
            this.getRidersClasses();
            this.addPersonaEntity = '';
            $('#editOwner').modal('hide');
            $('#loader').hide();
          } else {
            $('#loader').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        } catch (error) {
          this.handleError(error, "It has not been possible to update the person.");
        }
      }
    }
    $('#loader').hide();
  }

  public async selectEntry(entry): Promise<void> {
    $('#loader').show();
    this.pantalla = 1;
    this.entrySeleccionado = entry;
    const e = this.entries.find(e => e.entry == entry);
    this.nombrePayee = e ? e.payee : '';
    this.pruebasRepetidas = [];
    this.cards = [];
    this.emails = [];
    this.ownerId = '';
    this.payeeId = '';
    this.trainerId = '';
    this.tipoPersona = '';
    this.cargosSeleccionados = [];
    this.searchEntry = '';
    this.searchPerson = '';
    this.editarNotas = false;
    //Recalcular divisiones e inscripciones al cargar la cuenta
    await firstValueFrom(this.entriesService.recalcularDivisiones(this.entrySeleccionado, this.idConcurso, this.idUsuario));
    await this.getRidersClasses();//Si no carga el usef suspension status de los binomios, quitar async a this.ridersClasses
    //Tarjetas chase
    const { cards } = await firstValueFrom(this.entriesService.tarjetasRelacionadas(this.idConcurso, [this.entrySeleccionado])).then(r => ({ ...r, cards: r.cards.map(c => ({ ...c, ccAccountNum: c.ccAccountNum.replace(/[xX]/g, ''), ccExp: `${c.ccExp.substr(0, 4)}/${c.ccExp.substr(-2)}` })) }));
    this.cards = cards;
    const entryInfo = this.getEntryInfo();
    this.charges = this.entriesService.getCharges(entry, this.idConcurso).toPromise().then(r => {
      if (r.cargos.nombre == 'Class Entries') {
        console.log("Entry charges");
        console.log(r.cargos);
      }
      this.cargos = r.cargos;
      this.cargosInscripciones = r.cargosInscripciones;
      this.totalCargos = r.totalCargos;
      return r;
    });
    console.log("Entry charges", this.cargos);
    const orders = this.feedOrdersService.entrySummary(this.idConcurso, entry).toPromise().then(r => {
      this.summaryOrders = r.summary;
      return r;
    });
    const splits = this.entriesService.getSplits(entry, this.idConcurso).toPromise().then(r => {
      this.splits = r.splits;
      this.totalSplits = r.totalSplits;
      return r;
    });
    const payments = this.entriesService.getPayments(entry, this.idConcurso).toPromise().then(async r => {
      this.totalPagos = r.totalPagos;
      this.pagos = r.pagos.filter(p => !(p.referencia.includes('Check number: ') && p.referencia.includes(', amount: $') && p.monto == '0.00'));
      let index = 0;
      //Payment details
      try {
        for (const pago of this.pagos) {
          if(pago.metodo == 'Tc'){
            const pi_ = this.cleanString(pago.referencia);
            if (!pi_) continue;
            const paymentDetails = await this.stripeService.getPaymentDetails(pi_, this.idConcurso).toPromise();
            if (paymentDetails.error) {
              console.log(paymentDetails.message);
              continue;
            } else {
              this.pagos[index].paymentDetails = paymentDetails;
              this.pagos[index].referencia = `Payment ID: ${pi_}`;
              console.log(this.pagos[index]);
            }
          }
          index++;
        }
      } catch (error) {
        console.log(error);
      }
      return r;
    });
    const prizes = this.entriesService.getPrizes(entry, this.idConcurso).toPromise().then(r => {
      this.resultados = r.resultados;
      this.premios = r.premios;
      this.totalPremios = r.totalPremios;
      this.premiosAplicados = r.premiosAplicados;
      this.totalPremiosAplicados = r.totalPremiosAplicados;
      this.championshipSeries = r.championshipSeries;
      this.premiosChampionshipSeries = r.premiosChampionshipSeries;
      this.totalPremiosChampionshipSeries = r.totalPremiosChampionshipSeries;
      return;
    });
    Promise.all([entryInfo, this.charges, orders, splits, payments, prizes]).then(v => {
      //Limpiar formularios
      this.formCargo.reset();
      this.formCargo.setValue({
        concepto: '',
        notas: '',
        cantidad: '',
        monto: ''
      });
      this.formPago.reset();
      this.formPago.setValue({
        metodo: '',
        referencia: '',
        fecha: this.fecha,
        monto: ''
      });
      setTimeout(() => {
        $(() => {
          $('[data-toggle="tooltip"]').tooltip();
        })
      });
      $('#modal-productos').modal('hide');
      $('#loader').hide();
    });
  }

  private makeid(length) {
    var result = [];
    var characters = '123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
    }
    return result.join('');
  }

  public async addCreditCardPayment() {
    //Pago con tarjeta guardada
    if (this.tarjetaSeleccionada) {
        const card = this.cards.find(c => c.customerRefNum == this.tarjetaSeleccionada);
        if (!card) return;
        $('#loader').show();
        const trainers = this.entrenadoresEntry.map(e => e.idEntrenador).join(',');
        const ccAccountNum = card.ccAccountNum;
        try {
          const response = await firstValueFrom(this.chaseService.paymentToken(this.tarjetaSeleccionada, ccAccountNum, this.idConcurso, this.entrySeleccionado, this.formPago.value, this.idUsuario, trainers, this.retryTrace));
          $('#loader').hide()
          if (!response.error) {
            $('#cardPaymentModal').modal('hide');
            $.NotificationApp.send("Success", response.message, 'bottom-right', '#06d5a1', 'success');
            this.errorTarjeta = '';
            this.selectEntry(this.entrySeleccionado);
          } else {
            this.handleError(response.message, "It has not been possible to make the payment.");
            this.errorTarjeta = response.message;
          }
        } catch (error) {
          this.handleError(error, "It has not been possible to make the payment.");
        }
    } else {
      //Validar que los datos esten bien y completos
      if (this.cardForm.valid) {
        $('#loader').show();
        const trainers = this.entrenadoresEntry.map(e => e.idEntrenador).join(',');
        try {
          const response = await firstValueFrom(this.chaseService.payment(this.cardForm.value, this.idConcurso, this.entrySeleccionado, this.formPago.value, this.idUsuario, trainers, this.retryTrace));
          if (!response.error) {
            $('#cardPaymentModal').modal('hide');
            $.NotificationApp.send("Success", response.message, 'bottom-right', '#06d5a1', 'success');
            this.errorTarjeta = '';
            const emails = this.entrenadoresEntry.filter(e => e.email).map(e => e.email);
            for (const email of emails) {
              for (const card of response.tarjetas) {
                this.db.collection('cards').doc(email).collection('cards').doc(card.customerRefNum).set(card);
              }
            }
            this.selectEntry(this.entrySeleccionado);
          } else {
            this.handleError(response.message, "It has not been possible to make the payment.");
            this.errorTarjeta = response.message;
          }
        } catch (error) {
          this.handleError(error, "It has not been possible to make the payment.");
        }
      } else {
        $.NotificationApp.send("Error", 'Card form info is not valid.', 'bottom-right', '#fa5c7c', 'error');
      }
    }
  }

  public showAddEntry() {
    //Limpiar
    this.entryForm.get('entry').setValue('');
    this.entryForm.get('horse').setValue('');
    this.entryForm.get('payee').setValue('');
    this.entryForm.get('rider').setValue('');
    this.entryForm.get('trainer').setValue('');
    this.entryForm.get('owner').setValue('');
    this.newEntryRiders = [];
    //Cambiar a pantalla inicial
    this.pantalla = 0;
    this.entrySeleccionado = '';
    //Quitar clase de validacion de formulario entry
    $('#entryForm').removeClass('was-validated');
    //Limpiar arrays de consulta
    this.caballos = [];
    this.responsables = [];
    this.jinetes = [];
    this.entrenadores = [];
    this.owners = [];
    //Quitar botones de añadir
    this.addHorse = false;
    this.addPayee = false;
    this.addRider = false;
    this.addTrainer = false;
    this.addOwner = false;
    //Ocultar todos los paneles deagregar
    this.panelHorse = false;
    this.panelPayee = false;
    this.panelRider = false;
    this.panelTrainer = false;
    this.panelOwner = false;
    //Quitar id de entries
    this.idCaballo = '';
    this.trainerId = '';
    this.payeeId = '';
    this.ownerId = '';
    this.tipoPersona = '';
    $('#input-entry').focus();
  }

  public showEditRider(idBinomio, jinete, nombreJinete, entrenador, nombreEntrenador) {
    this.binomioSeleccionado = idBinomio;
    //Quitar clase de validacion de formulario entry
    $('#editRiderTrainerForm').removeClass('was-validated');
    //const index = this.binomios.findIndex(b => b.idBinomio == idBinomio);
    //Limpiar arrays de consulta y solo dejar el jinete y entrenador actual
    this.jinetes = [{
      id_persona: jinete,
      fullname: nombreJinete
    }];
    this.entrenadores = [{
      id_persona: entrenador,
      fullname: nombreEntrenador
    }];
    this.editRiderTrainerForm.setValue({
      rider: jinete,
      trainer: entrenador
    });
    $('#editRider').modal('show');
  }

  public showEditEntry(id_caballo, horse, id_responsable, payee) {
    //Quitar clase de validacion de formulario entry
    $('#editEntryForm').removeClass('was-validated');
    //const index = this.entries.findIndex(e => e.entry == this.entrySeleccionado);
    //Limpiar arrays de consulta y solo dejar el caballo y responsable actual
    this.caballos = [{
      id_caballo: id_caballo,
      fullname: horse
    }];
    this.responsables = [{
      id_persona: id_responsable,
      fullname: payee
    }];
    this.editEntryForm.setValue({
      horse: id_caballo,
      payee: id_responsable
    });
    $('#editEntry').modal('show');
  }

  public showchangeOwner(owner, ownerId) {
    //Quitar clase de validacion de formulario entry
    $('#changeOwnerForm').removeClass('was-validated');
    //Limpiar arrays de consulta y solo dejar el del dueño
    if (ownerId) {
      this.owners = [{
        id_persona: ownerId,
        fullname: owner
      }];
      this.changeOwnerForm.setValue({
        owner: ownerId,
      });
    } else {
      this.owners = [];
      this.changeOwnerForm.setValue({
        owner: '',
      });
    }
    $('#changeOwner').modal('show');
  }

  public showchangeTrainer(trainer, trainerId) {
    //Quitar clase de validacion de formulario entry
    $('#changeTrainerForm').removeClass('was-validated');
    //Limpiar arrays de consulta y solo dejar el del dueño
    if (trainerId) {
      this.entrenadores = [{
        id_persona: trainerId,
        fullname: trainer
      }];
      this.changeTrainerForm.setValue({
        trainer: trainerId,
      });
    } else {
      this.entrenadores = [];
      this.changeTrainerForm.setValue({
        trainer: '',
      });
    }
    $('#changeTrainer').modal('show');
  }

  public async showChangeHorse() {
    $('#loader').show();
    $('form').removeClass('was-validated');
    try {
      const response = await firstValueFrom(this.caballoService.getInfo(this.idCaballo));
      this.owners = [{
        id_persona: response.info.owner_id,
        fullname: response.info.owner_name
      }];
      if (!response.error) {
        this.horseForm.setValue({
          fei: response.info.fei,
          usef: response.info.usef_id,
          ushja: response.info.ushja_id,
          nsba: response.info.nsba_id,
          nrha: response.info.nrha,
          name: response.info.name,
          chip: response.info.chip_id,
          owner: response.info.owner_id,
          ownerUsef: '',
          country: response.info.country_of_birth,
          color: response.info.color,
          colorComplement: response.info.color_complement,
          breed: response.info.breed,
          gender: response.info.gender,
          dateOfBirth: response.info.date_of_birth,
          sire: response.info.sire,
          dam: response.info.dam,
          suspensionStatus: response.info.suspensionStatus,
          height: response.info.height,
          height_category: response.info.height_category,
          notes: response.info.notes
        });
        $('#loader').hide();
        $('#editHorse').modal('show');
      } else {
        this.handleError(response.message, "Error getting horse information.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to perform the query.");
    }
  }

  public async showEditPerson(tipo: string, id = -1) {
    let personId = '';
    switch (tipo) {
      case 'p':
        personId = this.payeeId;
        break;
      case 't':
        personId = this.trainerId;
        break;
      case 'o':
        personId = this.ownerId;
        break;
      case 'r':
        personId = id.toString();
        this.riderId = id.toString();
        break;
      default:
        break;
    }
    this.tipoPersona = tipo;
    this.addPersonaEntity = '';
    $('#loader').show();
    //this.ownerId = ownerId;
    this.organization = false;
    $('#personForm').removeClass('was-validated');
    try {
      const response = await firstValueFrom(this.personaService.getInfo(personId));
      if (!response.error) {
        this.personForm.setValue({
          fei: response.info.fei,
          usef: response.info.usef_id,
          ushja: response.info.ushja_id,
          nsba: response.info.nsba_id,
          nrha: response.info.nrha,
          name: response.info.name,
          lastname: response.info.lastname_1,
          maternalLastname: response.info.lastname_2,
          notas: response.info.notas,
          amateurProStatus: response.info.categoria,
          w9: response.info.w9 || '',
          email: response.info.email,
          age: response.info.age,
          nationality: response.info.id_nacionalidad == '' ? null : response.info.id_nacionalidad,
          dateOfBirth: response.info.fecha_de_nacimiento,
          telephone: response.info.telefono,
          cellPhone: response.info.celular,
          gender: response.info.genero,
          usefAddress: {
            country: response.info.usef_address.country || '',
            state: response.info.usef_address.state || '',
            city: response.info.usef_address.city || '',
            address: response.info.usef_address.address || '',
            address2: response.info.usef_address.address2 || '',
            zip: response.info.usef_address.zip || '',
          },
          taxAddress: {
            country: response.info.tax_address.country || '',
            state: response.info.tax_address.state || '',
            city: response.info.tax_address.city || '',
            address: response.info.tax_address.address || '',
            address2: response.info.tax_address.address2 || '',
            zip: response.info.tax_address.zip || '',
          },
          mailAddress: {
            country: response.info.mail_address.country || '',
            state: response.info.mail_address.state || '',
            city: response.info.mail_address.city || '',
            address: response.info.mail_address.address || '',
            address2: response.info.mail_address.address2 || '',
            zip: response.info.mail_address.zip || '',
          },
          taxId: response.info.rfc,
          taxName: response.info.razon_social,
          suspensionStatus: response.info.suspensionStatus,
          amateur: response.info.amateur,
          stripeCustomerId: response.info.stripe_customer_id || ''
        });
        this.organization = response.info.entity_type == 'O';
        $('#loader').hide();
        $('#editOwner').modal('show');
      } else {
        this.handleError(response.message, "Error getting person information.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to perform the query ");
    }
  }

  public showAddCombination() {
    //Quitar clase de validacion de formulario entry
    $('#addRiderTrainerForm').removeClass('was-validated');
    //Limpiar arrays de consulta
    this.jinetes = [];
    this.entrenadores = [];
    this.addRiderTrainerForm.setValue({
      rider: '',
      trainer: ''
    });
    if (this.entrenadoresEntry.length > 0) {
      this.entrenadores.push({
        id_persona: this.entrenadoresEntry[0].idEntrenador,
        fullname: this.entrenadoresEntry[0].name
      });
      this.addRiderTrainerForm.get('trainer').setValue(this.entrenadoresEntry[0].idEntrenador);
    }
    $('#addCombination').modal('show');
    setTimeout(() => {
      //this.classList.focus();
      $('#addCombinationInput').focus();
    }, 400);
  }

  public async editRider() {
    //Validar que el valor de responsable sea tomado desde la lista
    const indexJinete = this.jinetes.findIndex(p => p.id_persona == this.editRiderTrainerForm.get('rider').value);
    if (indexJinete == -1) {
      this.editRiderTrainerForm.get('rider').setValue('');
    }
    //Validar que el valor de responsable sea tomado desde la lista
    const indexEntrenador = this.entrenadores.findIndex(p => p.id_persona == this.editRiderTrainerForm.get('trainer').value);
    if (indexEntrenador == -1) {
      this.editRiderTrainerForm.get('trainer').setValue('');
    }
    //Validar que formulario este completo
    if (this.editRiderTrainerForm.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.editRiderTrainer(this.editRiderTrainerForm.get('rider').value, this.editRiderTrainerForm.get('trainer').value, this.binomioSeleccionado, this.idConcurso, this.entrySeleccionado));
        if (!response.error) {
          $('#editRider').modal('hide');
          this.reportesService.updateSuspendedMembershipPeople(this.idConcurso, [this.editRiderTrainerForm.value.rider, this.editRiderTrainerForm.value.trainer]).toPromise();
          this.selectEntry(this.entrySeleccionado);
        } else {
          this.handleError(response.message, "It has not been possible to change the rider and trainer.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to update the rider and trainer.");
      }
    }
  }

  public async editEntry() {
    //Validar que el valor de caballo sea tomado desde la lista
    const indexCaballo = this.caballos.findIndex(c => c.id_caballo == this.editEntryForm.get('horse').value);
    if (indexCaballo == -1) {
      this.editEntryForm.get('horse').setValue('');
    }
    //Validar que el valor de responsable sea tomado desde la lista
    const indexResponsable = this.responsables.findIndex(p => p.id_persona == this.editEntryForm.get('payee').value);
    if (indexResponsable == -1) {
      this.editEntryForm.get('payee').setValue('');
    }
    //Validar que formulario este completo
    if (this.editEntryForm.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.editHorsePayee(this.editEntryForm.get('horse').value, this.editEntryForm.get('payee').value, this.entrySeleccionado, this.idConcurso, this.idUsuario));
        if (!response.error) {
          $('#editEntry').modal('hide');
          this.reportesService.updateSuspendedMembershipHorses(this.idConcurso, [this.editEntryForm.value.horse]).toPromise();
          if(this.entrySeleccionado) this.selectEntry(this.entrySeleccionado);
        } else {
          this.handleError(response.message, "It has not been possible to update the entry.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to update the entry.");
      }
    }
  }

  public async changeOwner() {
    //Validar que el valor de owner sea tomado desde la lista
    const indexOwner = this.owners.findIndex(o => o.id_persona == this.changeOwnerForm.get('owner').value);
    if (indexOwner == -1) {
      this.changeOwnerForm.get('owner').setValue('');
    }
    //Validar que formulario este completo
    if (this.changeOwnerForm.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.changeOwner(this.idCaballo, this.changeOwnerForm.get('owner').value, this.idConcurso));
        if (!response.error) {
          this.getEntryInfo();
          this.getRidersClasses();
          $('#changeOwner').modal('hide');
          $('#loader').hide();
        } else {
          this.handleError(response.message, "It has not been possible to change the owner.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to change the owner.");
      }
    }
  }

  public async changeTrainer() {
    //Validar que el valor de owner sea tomado desde la lista
    const indexTrainer = this.entrenadores.findIndex(o => o.id_persona == this.changeTrainerForm.get('trainer').value);
    if (indexTrainer == -1) {
      this.changeTrainerForm.get('trainer').setValue('');
    }
    //Validar que formulario este completo
    if (this.changeTrainerForm.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.changeTrainer(this.idCaballo, this.changeTrainerForm.value.trainer, this.idConcurso));
        if (!response.error) {
          this.getEntryInfo();
          this.getRidersClasses();
          $('#changeTrainer').modal('hide');
          $('#loader').hide();
        } else {
          this.handleError(response.message, "It has not been possible to change the trainer.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to change the trainer.");
      }
      $('#loader').hide();
    }
  }

  public async addCombination() {
    const index = this.entries.findIndex(e => e.entry == this.entrySeleccionado);
    const horse = this.entries[index].id_caballo;
    const payee = this.entries[index].id_responsable;
    const owner = this.ownerId;
    //Validar que el valor de responsable sea tomado desde la lista
    const indexJinete = this.jinetes.findIndex(p => p.id_persona == this.addRiderTrainerForm.get('rider').value);
    if (indexJinete == -1) {
      this.addRiderTrainerForm.get('rider').setValue('');
    }
    //Validar que el valor de responsable sea tomado desde la lista
    const indexEntrenador = this.entrenadores.findIndex(p => p.id_persona == this.addRiderTrainerForm.get('trainer').value);
    if (indexEntrenador == -1) {
      this.addRiderTrainerForm.get('trainer').setValue('');
    }
    //Validar que formulario este completo
    if (this.addRiderTrainerForm.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.addCombination(this.addRiderTrainerForm.get('rider').value, this.addRiderTrainerForm.get('trainer').value, horse, payee, owner, this.entrySeleccionado, this.idConcurso));
        if (!response.error) {
          $('#addCombination').modal('hide');
          this.selectEntry(this.entrySeleccionado);
        } else {
          this.handleError(response.message);
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to add the combination.");
      }
    }
  }

  public showAddDrops(idBinomio, inscripciones) {
    this.extemporaneos = {};
    this.scratcheados = {};
    this.inscritos = {};

    for (const inscripcion of inscripciones) {
      this.scratcheados[`${inscripcion.ipc}-0`] = inscripcion.scratched;
      this.inscritos[`${inscripcion.ipc}-0`] = true;
    }

    //Arreglo formateado para mostrar las inscripciones en input chips
    let pruebas = [];
    for (const i in this.pruebas) {
      pruebas = pruebas.concat(this.pruebas[i]);
    }
    let inscritosChip = Object.keys(this.inscritos).map(p => {
      const ipc = p.split('-').shift();
      const index = pruebas.findIndex(pba => pba.ipc == ipc);
      return {
        ipc: pruebas[index].ipc,
        numero: pruebas[index].numero,
        fecha: pruebas[index].inicio,
        extemporaneo: false,
        estatus_prueba: pruebas[index].estatus
      };
    });
    for (const i in this.extemporaneos) {
      if (this.extemporaneos[i]) {
        const ipc = i.split('-').shift();
        const index = inscritosChip.findIndex(p => p.ipc == ipc);
        if (index > -1) {
          inscritosChip[index].extemporaneo = true;
        }
      }
    }
    this.inscritosChip = inscritosChip.sort((a, b) => {
      if (a.fecha > b.fecha) return 1;
      if (a.fecha < b.fecha) return -1;

      if (a.numero > b.numero) return 1;
      if (a.numero < b.numero) return -1;

      return 0;
    });
    this.binomioSeleccionado = idBinomio;
    this.pruebasFiltradas = [];
    for (let i in this.pruebas) {
      const pbas: any[] = this.pruebas[i].filter(p => Object.keys(this.inscritos).indexOf(`${p.ipc}-0`) > -1);
      if (pbas.length > 0) this.pruebasFiltradas.push({ key: i, value: pbas });
    }
    $('#addDrops').modal('show');
    setTimeout(() => {
      //this.classList.focus();
      $('#input-pruebas').focus();
    }, 400);
  }

  public async inscribir(ipc, idCategoria, refresh = false): Promise<void> {
    //$('#loader')this.inscritos.show();
    const bpc = {
      idBinomio: this.binomioSeleccionado,
      ipc: ipc,
      idUsuario: this.idUsuario,
      idConcurso: this.idConcurso,
      idCategoria: idCategoria,
      entry: this.entrySeleccionado
    };
    var continuar = true;
    refresh = true;
    if (!continuar) {
      $('#loader').hide();
      $.NotificationApp.send("Error", "This entry is already registered in this class.", 'bottom-right', '#fa5c7c', 'error');
    } else {
      return this.entriesService.inscribir(bpc).toPromise().then(
        async (response) => {
          if (!response.error) {
            //let binomios = JSON.stringify(this.binomios);
            //let prueba_exist = binomios.indexOf('"ipc":"' + ipc + '"') !== -1 ? true : false;
            //if (prueba_exist && response.accion == 'inscribir') {
            //  $.NotificationApp.send("Alert", 'This horse is already register in this class.', 'bottom-right', '#fa5c7c', 'warning');
            //}
            //Actualthis.inscritosizar resultados en app
            if (response.accion == 'inscribir') {
              this.inscritos[`${ipc}-${idCategoria}`] = true;
              delete this.scratcheados[`${ipc}-${idCategoria}`];
              delete this.extemporaneos[`${ipc}-${idCategoria}`];
              await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${ipc}`).set({ cambios: true }, { merge: true });
              this.appService.updateShow(this.idConcurso, ipc);
            } else if (response.accion == 'scratchear') {
              this.scratcheados[`${ipc}-${idCategoria}`] = true;
              delete this.inscritos[`${ipc}-${idCategoria}`];
              delete this.extemporaneos[`${ipc}-${idCategoria}`];
              this.appService.deleteEntriesAndUpdate(this.idConcurso, ipc);
            } else if (response.accion == 'desinscribir') {
              delete this.inscritos[`${ipc}-${idCategoria}`];
              delete this.scratcheados[`${ipc}-${idCategoria}`];
              delete this.extemporaneos[`${ipc}-${idCategoria}`];
              const indexBinomio = this.binomios.findIndex(b => b.idBinomio == this.binomioSeleccionado);
              if (indexBinomio > -1) {
                const indexPrueba = this.binomios[indexBinomio]['inscripciones'].findIndex(p => p.ipc == ipc);
                if (indexPrueba > -1) {
                  this.binomios[indexBinomio]['inscripciones'].splice(indexPrueba, 1);
                }
              }
              await this.appService.deleteEntriesAndUpdate(this.idConcurso, ipc);
            } else if (response.accion == 'extemporaneo') {
              this.extemporaneos[`${ipc}-${idCategoria}`] = true;
              delete this.inscritos[`${ipc}-${idCategoria}`];
              delete this.scratcheados[`${ipc}-${idCategoria}`];
              await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${ipc}`).set({ cambios: true }, { merge: true });
              this.appService.updateShow(this.idConcurso, ipc);
            }
            //Actualizar jueces si hay cambios
            await this.db.collection('concursos').doc(`us_${this.idConcurso}`).collection('pruebas').doc(`us_${ipc}`).set({ cambios: true, cambiosIngate: true }, { merge: true });
            //Arreglo formateado para mostrar las inscripciones en input chips
            let pruebas = [];
            for (const i in this.pruebas) {
              pruebas = pruebas.concat(this.pruebas[i]);
            }
            let inscritosChip = Object.keys(this.inscritos).map(p => {
              const ipc = p.split('-').shift();
              const index = pruebas.findIndex(pba => pba.ipc == ipc);

              return {
                ipc: pruebas[index].ipc,
                numero: pruebas[index].numero,
                fecha: pruebas[index].inicio,
                extemporaneo: false
              };
            });

            for(const i in this.extemporaneos) {
              if(this.extemporaneos[i]){
                const ipc = i.split('-').shift();
                const index = inscritosChip.findIndex(p => p.ipc == ipc);
                if (index > -1) {
                  inscritosChip[index].extemporaneo = true;
                }
              }
            }

            this.inscritosChip = inscritosChip.sort((a, b) => {
              if (a.fecha > b.fecha) return 1;
              if (a.fecha < b.fecha) return -1;

              if (a.numero > b.numero) return 1;
              if (a.numero < b.numero) return -1;

              return 0;
            });

            this.pruebasFiltradas = [];
            for (let i in this.pruebas) {
              const pbas: any[] = this.pruebas[i].filter(p => Object.keys(this.inscritos).indexOf(`${p.ipc}-0`) > -1);
              if (pbas.length > 0) this.pruebasFiltradas.push({ key: i, value: pbas });
            }

            if(refresh){
              this.getRidersClasses();
              this.getEntryInfo();
              Promise.all([this.charges]).then(v => {
                //Limpiar formularios
                this.formCargo.reset();
                this.formCargo.setValue({
                  concepto: '',
                  notas: '',
                  cantidad: '',
                  monto: ''
                });
                this.formPago.reset();
                this.formPago.setValue({
                  metodo: '',
                  referencia: '',
                  fecha: this.fecha,
                  monto: ''
                });
                $('#modal-productos').modal('hide');
                $('#loader').hide();
              });
            }
            //$('#loader').hide();
          } else {
            $('#loader').hide();
            $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
            console.log(response.message);
          }
        },
        error => {
          $('#loader').hide();
          console.log(error);
          $.NotificationApp.send("Error", 'It has not been possible to register the rider/horse combination.', 'bottom-right', '#fa5c7c', 'error');
        }
      );
    }
  }

  public guardarFirestore(ipc) {
    this.appService.updateShow(this.idConcurso, ipc);
  }

  public async eliminarBinomio(idBinomio, ipc) {
    $('#loader').show();
    try {
      const response = await firstValueFrom(this.entriesService.eliminarBinomioNP(idBinomio, ipc, this.idConcurso, this.idUsuario));
      if (!response.error) {
        this.db.collection('resultados').doc(`us_${ipc}`).collection('resultados').doc(`us_${idBinomio}`).delete();
        this.selectEntry(this.entrySeleccionado);
        $.NotificationApp.send("Deregistered", "Successfully deregistered the rider/horse combination.", 'bottom-right', '#06d5a1', 'success');
      } else {
        this.handleError(response.message, "It has not been possible to deregister the rider/horse combination.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to deregister the entry.");
    }
  }

  public riderSwapModal(ibpc) {
    this.ibpcSwapSeleccionado = ibpc;
    this.jinetes = [];
    this.formRiderSwap.setValue({
      rider: ''
    });
    $('form').removeClass('was-validated');
    $('#riderSwapModal').modal('show');
  }

  public async riderSwap() {
    //Validar que el valor de jinete sea tomado desde la lista
    const indexJinete = this.jinetes.findIndex(p => p.id_persona == this.formRiderSwap.get('rider').value);
    if (indexJinete == -1) {
      this.formRiderSwap.get('rider').setValue('');
    }

    if (this.formRiderSwap.valid) {
      $('#loader').show();
      try {
        const response = await firstValueFrom(this.entriesService.riderSwap(this.ibpcSwapSeleccionado, this.formRiderSwap.value.rider));
        if (!response.error) {
          this.selectEntry(this.entrySeleccionado);
          $('#riderSwapModal').modal('hide');
        } else {
          this.handleError(response.message, "It has not been possible to perform the rider swap.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to perform the rider swap.");
      }

    }
  }

  public desinscribir(idBinomio, ipc, categoria) {
    $('#loader').show();
    this.binomioSeleccionado = idBinomio;
    this.inscribir(ipc, categoria, true);
  }

  public traducirMetodoDePago(metodo): string {
    const metodos = {
      'Tc': 'Credit Card',
      'Cheque': 'Check',
      'Deposito': 'Bank Deposit',
      'Efectivo': 'Cash',
      'Transferencia': 'Wire Transfer',
      'Reembolso': 'Refund',
      'Cortesia': 'Courtesy',
      'Intercambio': 'Exchange',
      'Traspaso': 'Balance Transfer',
      'CC PrePayment': 'CC PrePayment',
      'ach': 'ACH',
      'PrizeMoney': 'Prize Money',
    };

    return metodos[metodo] ? metodos[metodo] : '';
  }

  public mostrarLog() {
    $('#logModal').modal('show');
  }

  public async listenerModalInscripcionesCerrado() {
    $('#addDrops').on('hidden.bs.modal', () => {
      $('#loader').show();
      this.getRidersClasses();
      this.charges = this.entriesService.getCharges(this.entrySeleccionado, this.idConcurso).toPromise().then(r => {
        this.cargos = r.cargos;
        this.cargosInscripciones = r.cargosInscripciones;
        this.totalCargos = r.totalCargos;
        return r;
      });
      this.getEntryInfo();

      Promise.all([this.charges]).then(v => {
        //Limpiar formularios
        this.formCargo.reset();
        this.formCargo.setValue({
          concepto: '',
          notas: '',
          cantidad: '',
          monto: ''
        });
        this.formPago.reset();
        this.formPago.setValue({
          metodo: '',
          referencia: '',
          fecha: this.fecha,
          monto: ''
        });
        $('#modal-productos').modal('hide');
        $('#loader').hide();
      });
    });
  }

  public async mostrarModalTraspaso(){
    $('form').removeClass('was-validated');
    this.entriesTraspaso = [];//this.entries.filter(e => e.entry != this.entrySeleccionado);
    this.formTraspaso.setValue({
      concurso: this.idConcurso,
      entry: '',
      fecha: this.fecha,
      notas: '',
      monto: '',
      deEntry: this.entrySeleccionado,
      deEntrenador: '',
      entrenador: '',
      deConcurso: this.idConcurso,
      idUsuario: this.idUsuario
    });
    this.filterBalanceTransferEntries = '';
    this.entrenadoresTraspaso = [];
    const response = await  firstValueFrom(this.entrenadorService.getEntrenadores(this.formTraspaso.value.concurso)).catch(r => ({ error: true, message: 'It has not been possible to consult the trainers list'}));
    if(response.error){
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      console.log(response.message);
      $('#loader').hide();
      return;
    }
    this.entrenadoresTraspaso = response.entrenadores;
    console.log(this.entrenadoresTraspaso);
    $('#loader').hide();
    $('#modal-traspaso').modal('show');
  }

  public async consultarEntrenadoresTraspaso(){
    $('#loader').show();
    this.formTraspaso.get('entrenador').setValue('');
    if (this.formTraspaso.value.concurso == this.idConcurso) {
      $('#loader').hide();
    } else {
      this.filterBalanceTransferEntries = '';
      this.entrenadoresTraspaso = [];
      const response = await firstValueFrom(this.entrenadorService.getEntrenadores(this.formTraspaso.value.concurso)).catch(r => ({ error: true, message: 'It has not been possible to consult the trainers list'}));
      if(response.error){
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
      this.entrenadoresTraspaso = response.entrenadores;
      console.log(this.entrenadoresTraspaso);
      $('#loader').hide();
    }
  }

  public async consultarEntriesTraspaso() {
    $('#loader').show();
    this.formTraspaso.get('entry').setValue('');
    if (this.formTraspaso.value.concurso == this.idConcurso) {
      this.entriesTraspaso = this.entries.filter(e => e.entry != this.entrySeleccionado);
      $('#loader').hide();
    } else {
      this.entriesTraspaso = [];
      try {
        const response = await firstValueFrom(this.entriesService.getEntries(this.formTraspaso.value.concurso));
        if (!response.error) {
          this.entriesTraspaso = response.data;
          $('#loader').hide();
        } else {
          this.handleError(response.message, "It has not been possible to consult the entry list.");
        }
      } catch (error) {
        this.handleError(error, "It has not been possible to consult the entry list.");
      }
    }
  }

  public async balanceTransfer() {
    if (this.formTraspaso.valid) {
      $('#loader').show();
      // verifica que el valor del entrenador pertenezca a un entrenador válido
      const trainer = this.trainersBalanceTransfer.find(t => t.idEntrenador == this.formTraspaso.value.entrenador);
      console.log(this.formTraspaso.value);
      if(trainer || this.formTraspaso.value.entry != ''){
        try {
          const response = await firstValueFrom(this.entriesService.balanceTransfer(this.formTraspaso.value));
          if (!response.error) {
            $('#modal-traspaso').modal('hide');
            this.selectEntry(this.entrySeleccionado);
            $.NotificationApp.send("Success", response.message, 'bottom-right', '#0acf97', 'success', 10000);
          } else {
            this.handleError(response.message, "It has not been possible to perform balance transfer.");
          }
        } catch (error) {
          this.handleError(error, "It has not been possible to perform balance transfer.");
        }
      } else {
        // si no lo encuentra y da click en save, limpia el campo e indica que el entrenador no es valido
        this.formTraspaso.get('entrenador').setValue('');
        $.NotificationApp.send("Error", "The selected trainer is not valid. Please Try again", 'bottom-right', '#fa5c7c', 'error');
        $('#loader').hide();
        return;
      }
    } else {
      $.NotificationApp.send("Error", "Some fields are empty", 'bottom-right', '#fa5c7c', 'error');
    }
  }

  public validarPruebaRepetida(ipc: string): boolean {
    //TODO: Hacer que funcione con la nueva estructura
    return this.pruebasRepetidas.indexOf(ipc) != -1;
  }

  public async addClass(event: MatChipInputEvent): Promise<void> {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      //Validar si el valor ingresado es una division o una prueba
      if (['d', 'D'].includes(value[0]||'')) {
        const division = value.slice(1);

        //Validar si valor ingrsado coincide con alguna division del concurso
        let pruebas = [];
        for (const i in this.pruebas) {
          pruebas = pruebas.concat(this.pruebas[i]);
        }

        const pruebasDivision = pruebas.filter(p => p.division_number == division);

        //Si no existen pruebas asignadas a esta division mostrar alerta
        if (pruebasDivision.length == 0) {
          $.NotificationApp.send("Warning", 'The division entered does not exist in this show.', 'bottom-right', '#ffbc00', 'warning', 10000);
          if (input) { input.value = ''; }
          return;
        }

        //Pruebas a las que ya esta inscrito
        const pruebasInscrito = this.inscritosChip.map(p => p.ipc);

        //Filtrar solo pruebas en las que aun no esta inscrito
        const pruebasFaltantes = pruebasDivision.filter(p => pruebasInscrito.indexOf(p.ipc) == -1);

        if (pruebasFaltantes.length == 0) {
          $.NotificationApp.send("Warning", 'This entry is already registered in the selected division.', 'bottom-right', '#ffbc00', 'warning', 10000);
          if (input) { input.value = ''; }
          return;
        }
        // Validar si el cabllo ya esta inscrito en esta prueba
        let inscritosChip = Object.keys(this.inscritos).map(p => {
          const ipc = p.split('-').shift();
          const index = pruebas.findIndex(pba => pba.ipc == ipc);

          return {
            ipc: pruebas[index].ipc,
            numero: pruebas[index].numero,
            fecha: pruebas[index].inicio,
            extemporaneo: false
          };
        });

        const promesasInscripcion = pruebasFaltantes.map(p => this.inscribir(p.ipc, '0'));

        Promise.all(promesasInscripcion).then(() => {
          if (input) { input.value = ''; }
          //TODO: Accion a realizar cuando se inscriba a todas las pruebas de la division
        });
        return;
      }

      //Validar si valor ingrsado coincide con alguna prueba del concurso
      let pruebas = [];
      for (const i in this.pruebas) {
        pruebas = pruebas.concat(this.pruebas[i]);
      }

      const index = pruebas.findIndex(p => p.numero == value.trim());
      if (index > -1) {
        const ipc = pruebas[index].ipc;

        if (this.inscritosChip.findIndex(p => p.ipc == ipc) == -1) {
          this.inscribir(ipc, 0);
        } else {
          $.NotificationApp.send("Warning", 'This rider/horse combination is already registered in the selected class.', 'bottom-right', '#ffbc00', 'warning', 10000);
        }
        if (input) { input.value = ''; }
      } else {
        $.NotificationApp.send("Warning", 'The class entered does not exist in this show.', 'bottom-right', '#ffbc00', 'warning', 10000);
        if (input) { input.value = ''; }
      }
    } else {
      if (input) { input.value = ''; }
    }
  }

  public fechaExp(fecha: string): string {
    return fecha.split('/').reverse().join('/');//`${fecha.substr(4, 2)}/${fecha.substr(0, 4)}`;
  }

  public fechaExpStripe(month: number, year: number): string {
    return `${month.toString().padStart(2, '0')}/${year}`;
  }

  public async validarCuenta(e) {
    const validated = e.target.checked ? 1 : 0;
    try {
      const response = await firstValueFrom(this.entriesService.validarCuenta(this.idConcurso, this.entrySeleccionado, validated));
      if (!response.error) {
        if (validated == 1) {
          this.guardarSaldoFS();
          //Desactivar formularios
          this.formCargo.disable();
          //this.formPago.disable();
        } else {
          this.borrarSaldoFS();
          //Activar formularios
          this.formCargo.enable();
          //this.formPago.enable();
        }
      } else {
        this.handleError(response.message, "It has not been possible to validate the invoice.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to validate the invoice.");
    }
  }

  public guardarSaldoFS() {
    //Solo guardar en FS si hay un mail al cual ligarlo y el saldo es mayor a 0
    //TODO: enviar saldo
    if (this.emails.length > 0/* && this.saldo > 0*/) {
      let cargos = this.cargos.map(c => {
        return {
          nombre: c.nombre,
          notas: '',
          cantidad: c.cantidad,
          monto: parseFloat(c.monto),
          detalle: c.detalle.map((d, index) => {
            return {
              index: index,
              nombre: c.nombre == 'Feed Orders' ? d.referencia : c.nombre,
              notas: d.referencia,
              cantidad: d.cantidad,
              monto: parseFloat(d.monto),
              anulado: false
            };
          })
        };
      });

      for (const binomio of this.binomios) {
        cargos.unshift({
          nombre: `Class Fees - ${binomio.nombre}`,
          notas: '',
          cantidad: '',
          monto: parseFloat(binomio.totalBinomio),
          detalle: binomio.inscripciones.map((i, index) => {
            return {
              index: index,
              nombre: `Class ${i.numero}`,
              notas: '',
              cantidad: '',
              monto: parseFloat(i.costo),
              anulado: i.scratched
            };
          })
        });
      }

      this.db.collection('saldos').doc(`us_${this.idConcurso}`).collection('entries').doc(this.entrySeleccionado).set({
        entry: this.entrySeleccionado,
        horse: this.nombreCaballo,
        saldo: 0/*this.saldo*/,
        inscripciones: this.binomios.map(b => {
          return {
            jinete: b.nombre,
            total: parseFloat(b.totalBinomio),
            pruebas: b.inscripciones.map((i, index) => {
              return {
                index: index,
                nombre: i.numero,
                inicio: i.inicio,
                costo: parseFloat(i.costo),
                scratched: i.scratched
              };
            })
          };
        }),
        //totalInscripciones: this.totalInscripciones,
        cargosInscripciones: this.cargosInscripciones,
        cargos: cargos,
        totalCargos: this.totalCargos,
        feedOrders: this.summaryOrders.map(o => {
          return {
            nombre: o.producto,
            cantidad: o.cantidad,
            total: parseFloat(o.total)
          };
        }),
        splits: this.splits.map(s => {
          return {
            nombre: s.entrenador,
            notas: s.notas,
            fecha: s.fecha,
            monto: parseFloat(s.monto),
            anulado: s.anulado
          };
        }),
        totalSplits: this.totalSplits,
        pagos: this.pagos.map(p => {
          return {
            metodo: this.traducirMetodoDePago(p.metodo),
            notas: p.referencia,
            monto: parseFloat(p.monto),
            fecha: p.fecha_pago,
            anulado: p.anulado
          };
        }),
        totalPagos: this.totalPagos,
        permios: this.premios.map(p => {
          return {
            rank: p.nombre,
            class: p.nombrePrueba,
            rider: p.jinete,
            amount: parseFloat(p.monto)
          };
        }),
        totalPremios: this.totalPremios,
        totalPremiosAplicados: this.totalPremiosAplicados,
        emails: this.emails
      });
    }
  }

  public borrarSaldoFS() {
    if (this.entrenadoresEntry.length > 0) {
      this.db.collection('saldos').doc(`us_${this.idConcurso}`).collection('entries').doc(this.entrySeleccionado).delete();
    }
  }

  public async anularTraspaso() {
    $('#loader').show();
    try {
      const response = await firstValueFrom(this.entriesService.cancelPayment(this.idTransaccionAnulacionTraspaso, 'Traspaso', this.montoAnulacionTraspaso, this.idUsuario, this.idConcurso, this.entrySeleccionado, this.motivoAnulacionTraspaso));
      if (!response.error) {
        this.selectEntry(this.entrySeleccionado);
        $('#motivoAnulacionTraspaso').modal('hide');
        $.NotificationApp.send("Canceled", response.message, 'bottom-right', '#06d5a1', 'success', 10000);
      } else {
        this.handleError(response.message, "It has not been possible to cancel the balance transfer.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to cancel the balance transfer.");
    }
  }

  private async getProducts() {
    try {
      const response = await firstValueFrom(this.productService.getProducts());
      (!response.error) ? this.products = response.products : this.handleError(response.message, "It has not been possible to query the products list.");
    } catch (error) {
      this.handleError(error, "It has not been possible to query the products list.");
    }
  }

  public mostrarModalProductos(): void {
    this.selectedProducts = [];
    this.selectedProduct = '';
    this.selectedQty = 1;
    this.selectedTotal = 0;
    $('#modal-productos').modal('show');
  }

  public changeTotal(): void {
    if (this.selectedProduct && this.selectedQty > 0) {
      const product = this.products.find(p => p.id == this.selectedProduct);
      this.selectedTotal = parseFloat(product.price) * this.selectedQty;
    }
  }

  public addProduct(): void {
    if (this.selectedProduct && this.selectedQty > 0) {
      const product = this.products.find(p => p.id == this.selectedProduct);
      if (this.selectedQty > parseInt(product.qty)) {//No hay inventario suficiente
        $.NotificationApp.send("Error", "Not enough products.", 'bottom-right', '#fa5c7c', 'error');
        return;
      }
      //Validar que hay suficiente inventario
      this.selectedProducts.push({
        id: product.id,
        name: product.name,
        sku: product.sku,
        qty: this.selectedQty,
        price: this.selectedTotal
      });
      this.selectedProduct = '';
      this.selectedQty = 1;
      this.selectedTotal = 0;
    }
  }

  public removeProduct(id) {
    const index = this.selectedProducts.findIndex(p => p.id == id);
    this.selectedProducts.splice(index, 1);
  }

  public async saveProductCharges(): Promise<void> {
    if (this.selectedProducts.length > 0) {
      $('#loader').show();
      const concepto = await this.productService.concept(this.idConcurso).toPromise().then(r => r.concepto);
      const requests = this.selectedProducts.map(p => {
        return this.entriesService.addCharge({
          concepto: concepto.id,
          cantidad: p.qty,
          monto: p.price,
          notas: p.name,
          entry: this.entrySeleccionado,
          idUsuario: this.idUsuario,
          idConcurso: this.idConcurso
        }).toPromise();
      });
      const requests2 = this.selectedProducts.map(p => {
        return this.productService.updateQty(p).toPromise();
      });
      Promise.all(requests.concat(requests2)).then(r => {
        this.getProducts();
        this.selectEntry(this.entrySeleccionado);
      });
    }
  }

  public selectCharge(id: string, monto: number): void {
    const index = this.cargosSeleccionados.findIndex(c => c.id == id);
    index == -1 ? this.cargosSeleccionados.push({ id, monto }) : this.cargosSeleccionados.splice(index, 1);
  }

  public async deleteSelectedCharges() {
    $('#loader').show();
    const chargePromises: Promise<any>[] = this.cargosSeleccionados.map(c => {
      return this.entriesService.cancelCharge(c.id, c.monto, this.idUsuario, this.idConcurso, this.entrySeleccionado).toPromise()
    });
    Promise.all(chargePromises).then(responses => {
      for (const response of responses) {
        if (response.error) {
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          $('#loader').hide();
          return;
        }
      }
      this.getEntryInfo();
      this.charges = this.entriesService.getCharges(this.entrySeleccionado, this.idConcurso).toPromise().then(r => {
        this.cargos = r.cargos;
        //this.totalInscripciones = r.inscripciones;
        this.cargosInscripciones = r.cargosInscripciones;

        this.totalCargos = r.totalCargos;
        return r;
      });
      const orders = this.feedOrdersService.entrySummary(this.idConcurso, this.entrySeleccionado).toPromise().then(r => {
        this.summaryOrders = r.summary;
        return r;
      });
      Promise.all([this.charges, orders]).then(v => {
        $.NotificationApp.send("Charges removed", 'The charges have been deleted successfully. ', 'bottom-right', '#06d5a1', 'success');
        $('#loader').hide();
      });
    });
  }

  public actualizarNotasTarjeta(): void {
    this.formPago.get('referencia').setValue(this.notasTarjeta);
  }

  public seleccionarCaballo(caballo) {
    if (caballo.owner_id && caballo.owner) {
      this.owners = [{
        id_persona: caballo.owner_id,
        fullname: caballo.owner
      }];
      this.responsables = [{
        id_persona: caballo.owner_id,
        fullname: caballo.owner
      }];
      this.entryForm.get('owner').setValue(caballo.owner_id);
      this.entryForm.get('payee').setValue(caballo.owner_id);
    }
  }

  public nuevoEntryInput(event) {
    this.nuevoEntry = event.target.value;
  }

  public showEditEntryNumber() {
    $('#editEntryNumber').modal('show');

  }

  public async changeEntryNumber() {
    $('#loader').show();
    try {
      const response = await firstValueFrom(this.entriesService.changeEntry(this.idUsuario, this.idConcurso, this.entrySeleccionado, this.nuevoEntry));
      if (!response.error) {
        this.selectEntry(this.nuevoEntry);
        //this.getEntries();
        this.nuevoEntry = '';
        $('#editEntryNumber').modal('hide');
        $('#loader').hide();
        $.NotificationApp.send("Success", response.message, 'bottom-right', '#06d5a1', 'success', 10000);
      } else {
        this.handleError(response.message, "It has not been possible to change the entry number.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to change the entry number.");
    }
  }

  public async recalcularDivisiones(): Promise<any> {
    return firstValueFrom(this.entriesService.recalcularDivisiones(this.entrySeleccionado, this.idConcurso, this.idUsuario)).then(async response => {
        if (!response.error) {
          await this.getRidersClasses();
          await this.getEntryInfo();
          this.charges = this.entriesService.getCharges(this.entrySeleccionado, this.idConcurso).toPromise().then(r => {
            this.cargos = r.cargos;
            this.cargosInscripciones = r.cargosInscripciones;
            this.totalCargos = r.totalCargos;
            return r;
          });
          Promise.all([this.charges]).then(v => {
            //Limpiar formularios
            this.formCargo.reset();
            this.formCargo.setValue({
              concepto: '',
              notas: '',
              cantidad: '',
              monto: ''
            });
            this.formPago.reset();
            this.formPago.setValue({
              metodo: '',
              referencia: '',
              fecha: this.fecha,
              monto: ''
            });
          });
        } else {
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", "An error has occurred. Please try again.", 'bottom-right', '#fa5c7c', 'error', 10000);
        console.log(error);
      }
    );
  }

  public async getHorseUSEFSuspensionInfo() {
    if (!this.usefId) {
      this.ownerLikeUsef = false;
      return;
    }

    return firstValueFrom(this.usefService.getUSEFhorseSuspensionInfo(this.usefId)).then(
      response => {
        if (!response.error) {
          const horse = response.data.horse[0];
          //Usef Suspension Status
          //this.horseSuspended = horse['SuspensionStatus'] == 'YES';
          //Microchip Status
          //this.chipValid = horse['MicrochipStatus'] == 'Eligible to Compete in Hunter/Jumper';
          //Ushja Suspension Status
          //this.horseMembership = !horse['RegAffilRecs'].find(m => m['MembershipType'] == 'USHJA Registered');
          //Owner Usef Match
          if (!horse['Owners']) {
            this.usefOwners = ['No USEF Owner Info'];
            this.ownerLikeUsef = false;
            return;
          }
          this.usefOwners = horse['Owners'].map(e => `${e['Id']}`);
          this.ownerLikeUsef = this.ownerUsefId && this.usefOwners.includes(this.ownerUsefId);
        } else {
          //console.log(response);
          //this.horseSuspended = true;
          //this.horseMembership = true;
          //this.chipValid = false;
          this.ownerLikeUsef = false;
          return;
        }
        return response;
      }
    ).catch(error => {
      $.NotificationApp.send(error.error.statusText||'', error.error.message, 'bottom-right', '#fa5c7c', 'error');
      return error;
     });
  }

  public async getHorseUSHJASuspensionInfo() {
    if (this.reining) { return };

    if (!this.usefId) {
      this.horseMembership = true;
      return;
    }
    return firstValueFrom(this.usefService.getUSHJAhorseSuspensionInfo(parseInt(this.usefId))).then(r => {
      if (r.data.length == 0) {
        this.horseMembership = false;
        return;
      }
      //const horse = r.data[0];
      const result = r.data[0] || false;
      const estatusActivos: string[] = ['Active', 'Expire soon'];
      this.horseMembership = !(result && estatusActivos.includes(result['RegistrationStatus']));
      console.log('Horse Membership: ', this.horseMembership);
      // Check if microchip is valid
      if (result['Microchip Status'] == 'Ineligible to Compete in Hunter/Jumper') {
        console.log('Microchip is not valid for USHJA');
        this.horseMembership = true;
        this.entryInfo.ushjaReason = 'Microchip is not valid for USHJA';
      }

      return r;
    }).catch(error => {
      console.log('Error: ', error);
      return '';
    });
  }

  public async getPersonUSEFSuspensionInfo(usefId, type, entityType = 'I') {
    if (this.reining) { return };
    if (!usefId) {
      const processedInfo = {
        usef: false,
        /*ushja: false,*/
        usefType: 'None',
        /*ushjaType: 'None',*/
        suspension: true,
        reason: 'No USEF number'
      }
      if (type == 'trainer') {
        this.trainer = processedInfo;
      } else if (type == 'owner') {
        this.owner = processedInfo;
      } else {
        //this.personSuspensionData[usefId] = processedInfo;
      }
      return;
    }
    this.usefService.getUSEFpersonSuspensionInfo(usefId).toPromise().then(r => {
      if (r['TotalMatched'] == 0) {
        const processedInfo = {
          usef: false,
          /*ushja: false,*/
          usefType: 'None',
          /*ushjaType: 'None',*/
          suspension: true,
          reason: 'No known USEF number'
        }
        if (type == 'trainer') {
          this.trainer = processedInfo;
        } else if (type == 'owner') {
          this.owner = processedInfo;
        } else {
          if (!this.personSuspensionData[`${usefId}`]) this.personSuspensionData[usefId] = {};
          this.personSuspensionData[`${usefId}`]['usef'] = processedInfo.usef;
          this.personSuspensionData[`${usefId}`]['usefType'] = processedInfo.usefType;
          this.personSuspensionData[`${usefId}`]['suspension'] = processedInfo.suspension;
          this.personSuspensionData[`${usefId}`]['reason'] = processedInfo.reason;
        }
        return;
      }
      let person;
      if (r['TotalMatched'] > 1) {
        person = r.indivOrgs.find(i => i['EntityType'] == entityType) || r.indivOrgs[0];
      } else {
        person = r.indivOrgs[0];
      }
      //Usef Suspension Status
      const USEFMembership = person['Memberships'].find(m => m['OrgName'] == 'USEF');
      /*const USHJAMembership = person['Memberships'].find(m => m['OrgName'] == 'USHJA');*/

      const processedInfo = {
        usef: USEFMembership && USEFMembership['Status'] == 'ACTIVE',
        /*ushja: USHJAMembership && USHJAMembership['Status'] == 'ACTIVE',*/
        usefType: USEFMembership && USEFMembership['MembershipType'] ? USEFMembership['MembershipType'] : '',
        /*ushjaType: USHJAMembership && USHJAMembership['MembershipType'] ? USHJAMembership['MembershipType'] : '',*/
        suspension: person['SuspensionStatus'] ? person['SuspensionStatus'] != 'NO' : true,
        reason: person['SuspensionStatus'] ? `${person['SuspensionStatus']} ${person['SuspensionReason'] || ''} ${person['SuspensionDetails'] || ''}` : `Not found`
      }

      //console.log(person);
      //console.log(processedInfo);

      if (type == 'trainer') {
        this.trainer = processedInfo;
      } else if (type == 'owner') {
        this.owner = processedInfo;
      } else {
        if (!this.personSuspensionData[`${usefId}`]) this.personSuspensionData[usefId] = {};
        this.personSuspensionData[`${usefId}`]['usef'] = processedInfo.usef;
        this.personSuspensionData[`${usefId}`]['usefType'] = processedInfo.usefType;
        this.personSuspensionData[`${usefId}`]['suspension'] = processedInfo.suspension;
        this.personSuspensionData[`${usefId}`]['reason'] = processedInfo.reason;
      }
      return;
    });
  }

  public async getPersonUSHJASuspensionInfo(usefId, type, entityType = 'I') {
    if (this.reining) { return }
    if (!usefId) {
      const processedInfo = {
        ushja: false,
        ushjaType: 'None',
      };
      if (type == 'trainer') {
        this.trainer.ushja = processedInfo.ushja;
        this.trainer.ushjaType = processedInfo.ushjaType;
      } else if (type == 'owner') {
        this.owner.ushja = processedInfo.ushja;
        this.owner.ushjaType = processedInfo.ushjaType;
      } else {
        /*if(!this.personSuspensionData[usefId]) this.personSuspensionData[usefId] = {};
        this.personSuspensionData[usefId]['ushja'] = processedInfo.ushja;
        this.personSuspensionData[usefId]['ushjaType'] = processedInfo.ushjaType;*/
      }
      return;
    }
    return firstValueFrom(this.usefService.getUSHJApersonSuspensionInfo(parseInt(usefId), 'I')).then(r => {
      if (r.data.length == 0) {
        const processedInfo = {
          ushja: false,
          ushjaType: 'None',
        };
        if (type == 'trainer') {
          this.trainer.ushja = processedInfo.ushja;
          this.trainer.ushjaType = processedInfo.ushjaType;
        } else if (type == 'owner') {
          this.owner.ushja = processedInfo.ushja;
          this.owner.ushjaType = processedInfo.ushjaType;
        } else {
          if (!this.personSuspensionData[`${usefId}`]) this.personSuspensionData[usefId] = {};
          this.personSuspensionData[`${usefId}`]['ushja'] = processedInfo.ushja;
          this.personSuspensionData[`${usefId}`]['ushjaType'] = processedInfo.ushjaType;
        }
        return;
      }
      const person = r.data[0];
      const result = person.result[0] || false;

      const estatusActivos: string[] = ['Valid', 'Expire soon'];
      let ushjaType = result && result['MembershipName'] ? [`Membership Name: ${result['MembershipName']}`] : [person['Message']];
      if (result && !estatusActivos.includes(result['RegistrationStatus'])) ushjaType.push(`Registration Status: ${result['RegistrationStatus']}`);
      const processedInfo = {
        ushja: result && estatusActivos.includes(result['RegistrationStatus']),
        ushjaType: ushjaType.join(' - ')
      }
      if (type == 'trainer') {
        this.trainer.ushja = processedInfo.ushja;
        this.trainer.ushjaType = processedInfo.ushjaType;
      } else if (type == 'owner') {
        this.owner.ushja = processedInfo.ushja;
        this.owner.ushjaType = processedInfo.ushjaType;
      } else {
        if (!this.personSuspensionData[`${usefId}`]) this.personSuspensionData[usefId] = {};
        this.personSuspensionData[`${usefId}`]['ushja'] = processedInfo.ushja;
        this.personSuspensionData[`${usefId}`]['ushjaType'] = processedInfo.ushjaType;
      }

      return r;
    }).catch(error => {
      console.log('Error: ', error);
      return '';
    });
  }

  public async applyPrize(event, premio) {
    $('#loader').show();
    const checked = event.target.checked;
    const datos = {
      idUsuario: this.idUsuario,
      ipc: premio.ipc,
      idPremio: premio.id,
      idBinomio: premio.id_binomio,
      nombre: premio.nombre,
      monto: Number(premio.monto),
      deduccion: Number(premio.deduccion),
      pagarAparte: checked ? '1' : '0',
      referencia: premio.referencia
    };

    try {
      const response = await firstValueFrom(this.pruebaService.agregarPremio(datos));
      if (!response.error) {
        this.getEntryInfo();
        this.getRidersClasses();
        const prizes = this.entriesService.getPrizes(this.entrySeleccionado, this.idConcurso).toPromise().then(r => {
          this.premios = r.premios;
          this.totalPremios = r.totalPremios;
          this.premiosAplicados = r.premiosAplicados;
          this.totalPremiosAplicados = r.totalPremiosAplicados;
          this.championshipSeries = r.championshipSeries;
          this.premiosChampionshipSeries = r.premiosChampionshipSeries;
          this.totalPremiosChampionshipSeries = r.totalPremiosChampionshipSeries;
          return;
        });
        Promise.all([prizes]).then(v => {
          $('#loader').hide();
        });
      } else {
        this.handleError(response.message, "It has not been possible to apply the prize.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to apply the prize.");
    }
  }

  public async saveNotes(e) {
    try {
      const response = await firstValueFrom(this.entriesService.saveNotes(this.idConcurso, this.entrySeleccionado, e));
      if (!response.error) {
        this.editarNotas = e == '';
        this.entryNotes = e;
      } else {
        this.handleError(response.message, "It has not been possible to save the notes.");
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to save the notes.");
    }
  }

  public async getEntryInfo(): Promise<any> {
    return firstValueFrom(this.entriesService.getEntryInfo(this.entrySeleccionado, this.idConcurso)).then(async i => {
      this.entryInfo = i;
      this.idCaballo = i.id_caballo;
      this.ownerId = i.ownerId;
      this.payeeId = i.payeeId;
      this.trainerId = i.trainerId;
      this.nombreCaballo = i.horse;
      this.validated = i.validated;
      this.entryNotes = i.entryNotes;
      this.editarNotas = !this.entryNotes;
      this.entrenadoresEntry = [{ idEntrenador: i.trainerId }];
      this.usefId = i.usefId;
      this.trainerEmail = i.trainerEmail;
      this.trainerName = i.trainer;

      this.feiId = i.feiId;
      this.altura = i.altura;
      this.chip_id = i.chip_id;
      this.ownerUsefId = i.usefOwnerId;
      this.horseSuspended = i.usef_suspended_horse;
      this.horseSuspendedReason = i.usef_suspension_reason_horse;
      this.invoiceFooter = i.invoiceFooter;
      this.owner = {
        suspension: i.usef_suspended_owner,
        reason: i.usef_suspension_reason_owner
      };
      this.trainer = {
        suspension: i.usef_suspended_trainer,
        reason: i.usef_suspension_reason_trainer
      };
      //Stripe
      this.stripeCustomers = i.stripe_customers;
      this.useStripe = i.useStripe;
      if(this.stripeCustomers){
        this.emails = this.stripeCustomers.filter(c => c.email).map(c => c.email).filter((e,i,a) => a.indexOf(e) === i);
      } else {
        this.emails = [];
      }
      //Consultar Stripe customers con los emails guardados en mysql
      if(this.useStripe && this.emails.length){
        const responseSearchCustomers = await firstValueFrom(this.stripeService.searchCustomers(this.emails, `${this.idConcurso}`));
        if(responseSearchCustomers.length >0){
          this.stripeCustomers = [
            ...this.stripeCustomers,
            ...responseSearchCustomers.map(c => ({ id: c.id||'', name: c.name||'No name', email: c.email||'', paymentMethods: [] }))
          ].filter((c,i,a) => a.findIndex(e => e.id == c.id) === i);
        }
      }
      console.log(this.stripeCustomers);
      //Filtrar clientes repetidos o que no tengan id
      if(this.stripeCustomers) this.stripeCustomers = this.stripeCustomers.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i && v.id);
      //Check Horse Suspension Status (para revisar el ownerLikeUsef)
      await this.getHorseUSEFSuspensionInfo();
      await this.getHorseUSHJASuspensionInfo();
      await this.getPersonUSHJASuspensionInfo(i.usefOwnerId, 'owner', i.ownerEntityType);
      //Check Trainer Suspension Status
      await this.getPersonUSHJASuspensionInfo(i.usefTrainerId, 'trainer', i.trainerEntityType);
      for(const binomio of this.binomios){
        await this.getPersonUSHJASuspensionInfo(binomio.usefID, 'rider');
      }

      //Si esta marcada como validada desactivar formularios
      this.validated ? this.formCargo.disable() : this.formCargo.enable();

      return i;
    });
  }

  public agregarComision(amount: number, feeAmount: number, onlyComision: boolean = false): number {
    const incluirMonto = onlyComision ? 0 : 1;
    const comision = incluirMonto + feeAmount;
    return amount * comision;
  }

  public async getRidersClasses(): Promise<any> {
    return firstValueFrom(this.entriesService.getRidersClasses(this.entrySeleccionado, this.idConcurso)).then(r => {
      this.ridersClasses = r;
      this.binomios = r;
      return r;
    });
  }

  addedCheck(e) {
  }

  public toggleEditCardholderName(e, stripeCard) {
    e.stopPropagation();
    stripeCard.editable = !stripeCard.editable;
    if (stripeCard.editable) {
      setTimeout(() => {
        $(`#${stripeCard.id}`).select();
      });
    }
  }

  public async updateCardholderName(e, stripeCard) {
    const name = e.target.value;
    const { paymentMethod, message } = await this.stripeService.updateCardholderName(stripeCard.id, name, this.idConcurso).toPromise();
    if (message) {
      $.NotificationApp.send("Error", message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    stripeCard.billing_details.name = paymentMethod.billing_details.name;
    stripeCard.editable = false;
  }

  public async deleteCard(customerRefNum, personID) {
    const response = await this.chaseService.deleteCard(customerRefNum, personID).toPromise();
    if (response.error) {
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const tarjetas = await firstValueFrom(this.entriesService.tarjetasRelacionadas(this.idConcurso, [this.entrySeleccionado])).then(r => {
      this.cards = (r.cards||[]).map(c => ({ ...c, ccAccountNum: c.ccAccountNum.replace(/[xX]/g, ''), ccExp: `${c.ccExp.substr(0, 4)}/${c.ccExp.substr(-2)}` }));
      return r;
    });
  }

  public showAddCard() {
    this.cardForm.reset({
      number: '',
      date: '',
      name: '',
      save: false
    });
    this.showAddCardForm = true;
    setTimeout(() => {
      $.App.init();
    }, 500);
  }

  public hideAddCard() {
    this.showAddCardForm = false;
  }

  public async addCard() {
    if (this.cardForm.valid) {
      const [{ idEntrenador }] = this.entrenadoresEntry;
      const card = {
        idConcurso: this.idConcurso,
        idPersona: idEntrenador,
        customerName: this.cardForm.value.name,
        customerAddress1: '',
        customerAddress2: '',
        customerCity: '',
        customerState: '',
        customerZIP: '',
        customerPhone: '',
        customerCountryCode: '',
        ccAccountNum: this.cardForm.value.number,
        ccExp: this.cardForm.value.date
      };
      const response: any = await this.chaseService.addCard(card).toPromise();
      if (response.error) {
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
        return;
      }
      this.showAddCardForm = false;
      await this.entriesService.tarjetasRelacionadas(this.idConcurso, [this.entrySeleccionado]).toPromise().then(r => {
        this.cards = r.cards.map(c => ({ ...c, ccAccountNum: c.ccAccountNum.replace(/[xX]/g, ''), ccExp: `${c.ccExp.substr(0, 4)}/${c.ccExp.substr(-2)}` }));
        return r;
      });
    }
  }

  public async addCardFromPayment(id, ccAccountNum, ccExpMonth, ccExpYear) {
    const i = this.pagos.findIndex(p => p.id == id);
    if (i > -1) this.pagos[i].loading = true;
    const [{ idEntrenador }] = this.entrenadoresEntry;
    const card = {
      idConcurso: this.idConcurso,
      idPersona: idEntrenador,
      customerName: 'No Name',
      customerAddress1: '',
      customerAddress2: '',
      customerCity: '',
      customerState: '',
      customerZIP: '',
      customerPhone: '',
      customerCountryCode: '',
      ccAccountNum,
      ccExp: `${ccExpMonth}/${ccExpYear}`
    };
    const { error, message } = await this.chaseService.addCard(card).toPromise();
    if (error) {
      $.NotificationApp.send("Error", message, 'bottom-right', '#fa5c7c', 'error');
      if (i > -1) this.pagos[i].loading = false;
      return;
    }

    await this.entriesService.tarjetasRelacionadas(this.idConcurso, [this.entrySeleccionado]).toPromise().then(r => {
      this.cards = r.cards.map(c => ({ ...c, ccAccountNum: c.ccAccountNum.replace(/[xX]/g, ''), ccExp: `${c.ccExp.substr(0, 4)}/${c.ccExp.substr(-2)}` }));
      return r;
    });

    if (i > -1) this.pagos[i].loading = false;
  }

  public async removeRiderFromEntry(binomio) {
    try {
      const response = await firstValueFrom(this.entriesService.removeRider(binomio, this.idUsuario));
      if (!response.error) {
        this.ridersClasses = this.ridersClasses.filter(r => r.binomio != binomio);
        $.NotificationApp.send('Success', "The rider has been removed successfully.", 'bottom-right', '#0acf97', 'success', 5000);
      } else {
        $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error', 10000);
        console.log(response.message);
      }
    } catch (error) {
      this.handleError(error, "It has not been possible to remove the rider.");
    }
  }

  public async getPersonNrhaData() {
    this.cargando = true;
    const nrha = this.personForm.value.nrha;

    console.log('Get NRHA data: ', nrha);
    if (!nrha) {
      this.cargando = false;
      $.NotificationApp.send("Invalid NRHA ID.", "The Nrha ID is invalid or not assigned.", 'bottom-right', '#ffbc00', 'warning');
      return;
    }
    const response = await this.personaService.getNRHAInfo(nrha).toPromise().then(r => r || {}, r => r || {});
    if (response.error) {
      $.NotificationApp.send('Error', response.message, 'bottom-right', '#ffbc00', 'warning');
      this.cargando = false;
      return;
    }

    let data = response[0];
    this.personForm.patchValue({
      name: (data.firstName || '') + (data.middleName ? ' ' + data.middleName : ''),
      lastname: data.lastName ?? '',
      email: data.emailAddress ?? '',
      telephone: data.phoneNumber ?? '',
      usefAddress: {
        address: data.line1 ?? '',
        address2: data.line2 ?? '',
        city: data.city ?? '',
        state: data.state ?? '',
        zip: data.zip ?? '',
        country: data.country ?? ''
      }

    });


    // const horseName = response.horseName||'';
    // const foalDate = response.foalDate||'';
    // const ownerMemberNumber = response.ownerMemberNumber||'';
    // const ownerName = (response.ownerName||'').trim();
    // const gender = (response.sex||'').trim();
    // const damName = (response.damName||'').trim();
    // const sireName = (response.sireName||'').trim();
    // this.personForm.patchValue({
    //   name: horseName||'',
    //   dateOfBirth: foalDate||'',
    //   gender,
    //   dam: damName||'',
    //   sire: sireName||''
    // });
    this.cargando = false;
  }

  public async getPaymentInfo(data, i) {
    console.log('Getting Payment Info: ', data, i);
    const paymentIntentId = data.match(/pi_[^\s]+/)[0];
    console.log('Payment Intent ID: ', paymentIntentId);

    const response = await this.stripeService.getPaymentInfo(paymentIntentId, this.idConcurso).toPromise().then(r => r, r => r);

    this.pagos[i].moreInfo = response.message;
    // return paymentIntentId;

  }

  /** Stripe */

  public async handleSubmit(paymentIntent: any) {
    if (paymentIntent.status != 'succeeded') {
      $.NotificationApp.send("Error", `Payment failed, status: ${paymentIntent.status}`, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    const cardFee = paymentIntent.cardFee;
    const paymentIntentId = paymentIntent.id;

    $('#cardPaymentStripeModal').modal('hide');
    this.addPayment({ pi: paymentIntentId, comision: cardFee });
    //$.NotificationApp.send('Success', "The payment has been made successfully.", 'bottom-right', '#0acf97', 'success', 5000);
    return;
  }

  public handleError(reason: any, messageToUser?: string) {
    $('#loader').hide();
    const message = (reason.error || {}).text || (((reason.error || {}).error || {}).message || reason.message || reason.error || 'Error during request.');
    $.NotificationApp.send("Error", messageToUser || message, 'bottom-right', '#fa5c7c', 'error');
    console.log(`%c Handling Error:`, "color: white; font-size: 11px; background-color: #a02d24; padding: 1px 2px; border-radius: 3px;");
    console.error(message);
    return { error: true, message: messageToUser || message };
  }

  public cleanString(input: string): string {
    console.log(input)
    const regex = /pi_\w+/;
    // encuentra el primer elemento que cumpla con la expresión regular
    const match = input.match(regex);
    // si hay un match, devolver el valor, si no, devolver un string vacío (falsy)
    console.log(match)
    return match ? match[0] : '';
  }

  //Paginacion
  public onGoTo(page: number): void {
    this.current = page;
  }

  public onNext(page: number): void {
    this.current = page+1;
  }

  public onPrevious(page: number): void {
    this.current = page-1;
  }

  public async getEntriesPaginated(page: number) {
    const response = await this.entriesService.getEntriesPaginated(this.idConcurso, page);
    if(response.error){
      $('#loader').hide();
      $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
      return;
    }
    this.entries = (response.items||[]).map(e => ({ ...e, id: e.id_binomio, horse: e.caballo.fullname, id_caballo: e.idCaballo, id_concurso: e.idConcurso, id_responsable: e.idResponsable, trainers_id: e.idEntrenador, trainers: `${e.trainer.name} ${e.trainer.lastname_1}`, riders: `${e.rider.name} ${e.rider.lastname_1}`}));
    this.total = response.meta.totalPages;
    this.totalItems = response.meta.totalItems;
    if(this.entrySeleccionado) this.selectEntry(this.entrySeleccionado);
    $('#loader').hide();
    console.log('totalItems', response.meta.totalItems||'');
    console.log('totalPages', response.meta.totalPages||'');
    console.log('currentPage', response.meta.currentPage||'');
    console.log('itemCount', response.meta.itemCount||'');
    console.log('itemsPerPage', response.meta.itemsPerPage||'');
  }
}
