import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class UsefService extends Api {
  public USEFAPI: string;
  public USHJA: string;
  constructor(private http: HttpClient) {
    super();
    this.USEFAPI = "595ff1dad4c3459c8dcfdb80e10c4a17:Ynx4KWpOR3ElChRjsAF3QXpcYp1pC9M0f50kHp2BJkk=";
    this.USHJA = "60f9079d-8d45-40a6-be45-498b1917586a:9ec1c622-95d1-404d-be94-e1e2bd809ca0";
  }

  public getUSEFhorseSuspensionInfo(Id): Observable<any> {
    const params = JSON.stringify({ Id });
    const headers = {
      'Authorization': this.USEFAPI,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    //return this.http.post('https://api.usef.org/api/cscp/production/horse/getbyid', params, { headers: headers });
    return this.http.get(`${environment.api.nest.uri}usef/horse/${Id}`);
  }



  public getUSHJAhorseSuspensionInfo(USEFId, EntityType = 'H'): Observable<any> {
    const params = JSON.stringify([{ USEFId, EntityType }]);
    const headers = {
      'Authorization': this.USHJA,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    //return this.http.post('https://myapi.ushja.org/competition/getUSHJAstatus', params, { headers: headers });
    return this.http.get(`${environment.api.nest.uri}ushja/status/${USEFId}/H`);
  }

  public getUSEFpersonSuspensionInfo(Id): Observable<any> {
    const params = JSON.stringify({ Id });
    const headers = {
      'Authorization': this.USEFAPI,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post('https://api.usef.org/api/cscp/production/indivorg/getbyid', params, { headers: headers });
  }

  public getUSHJApersonSuspensionInfo(USEFId, EntityType): Observable<any> {
    const params = JSON.stringify([{ USEFId, EntityType }]);
    const headers = {
      'Authorization': this.USHJA,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post('https://myapi.ushja.org/competition/getUSHJAstatus', params, { headers: headers });
  }

  public getUSHJApersonSuspensionInfoBulk(USEFId): Observable<any> {
    const params = JSON.stringify(USEFId);
    const headers = {
      'Authorization': this.USHJA,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post('https://myapi.ushja.org/competition/getUSHJAstatus', params, { headers: headers });
  }

  public getUSHJAHorseSuspensionInfoBulk(USEFId): Observable<any> {
    const params = JSON.stringify(USEFId);
    const headers = {
      'Authorization': this.USHJA,
      'Content-Type': 'application/json;charset=UTF-8'
    };
    return this.http.post('https://myapi.ushja.org/competition/getUSHJAstatus', params, { headers: headers });
  }

}
