import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListaService } from '../services/lista.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EntriesService } from '../services/entries.service';
declare let $: any;

@Component({
  selector: 'app-order-paddock',
  templateUrl: './order-paddock.component.html',
  styleUrls: ['./order-paddock.component.css']
})
export class OrderPaddockComponent implements OnInit {
  private ipc: string;
  public entries: any[];
  public prueba: any;

  constructor(private route: ActivatedRoute, private listaService: ListaService, private db: AngularFirestore, private entriesService: EntriesService) {
    this.ipc = this.route.snapshot.paramMap.get('ipc');
    this.entries = [];
    this.prueba = {};
  }

  ngOnInit() {
    this.getEntries();
  }

  public getEntries(): void{
    $('#loader').show();
    this.listaService.getBinomios(this.ipc).subscribe(
      response => {
        if(!response.error){
          this.entries = response.binomios.filter(b => b.calificado == '0');
          this.prueba = response.info;
          $('#loader').hide();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'The entry list could not be consulted.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }

  public cambiarEntrada(e, ibpc): void{
    $('#loader').show();
    const index = this.entries.findIndex(b => b.ibpc == ibpc);
    const entradaNueva = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) + ((!isNaN(parseFloat(this.entries[index].entradaPublicada)) ? parseFloat(this.entries[index].entradaPublicada) : 0) > parseFloat(e.target.value) ? -.5 : .5/* .5*/) : 0;
    this.entries[index].entradaPublicada = entradaNueva;

    const binomios = this.entries.sort((a, b) => {
      const entradaA = !isNaN(parseFloat(a.entradaPublicada)) ? parseFloat(a.entradaPublicada) : 0;
      const entradaB = !isNaN(parseFloat(b.entradaPublicada)) ? parseFloat(b.entradaPublicada) : 0;

      if(entradaA > entradaB) return 1;
      if(entradaA < entradaB) return -1;

      return 0;
    }).filter(b => {
      const entrada = !isNaN(parseFloat(b.entradaPublicada)) ? parseFloat(b.entradaPublicada) : 0;

      return entrada > 0;
    }).map((b, i) => {
      b.entradaPublicada = i+1;

      return b;
    }).map(b => {
      return this.listaService.cambiarEntrada([{
        ibpc: b.ibpc,
        idBinomio: b.idBinomio,
        ipc: this.ipc,
        idConcurso: this.prueba.idConcurso,
        entrada: b.entradaPublicada,
        remoto: b.remoto ? 1 : 0
      }]).toPromise().catch(reason => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to modify the entry number, please try again.', 'bottom-right', '#fa5c7c', 'error');
        console.log(reason);
      });
    });
    
    Promise.all(binomios).then(v => {
      this.db.collection('pruebas').doc(`us_${this.prueba.idConcurso}`).collection('pruebas').doc(`us_${this.ipc}`).set({
        cambios: true
      }, { merge: true });
      $('#loader').hide();
      $.NotificationApp.send("Success", "The entry number has been successfully updated.", 'bottom-right', '#0acf97', 'success');
    });

    /*this.listaService.cambiarEntrada(binomios).subscribe(
      response => {
        if(!response.error){
          //this.getEntries();
          
          
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to modify the entry number, please try again.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );*/
  }

  public scratch(ibpc: string, idBinomio: string): void{
    $('#loader').show();
    this.entriesService.scratch(idBinomio, this.ipc).subscribe(
      response => {
        if(!response.error){
          const index = this.entries.findIndex(e => e.ibpc == ibpc);
          this.entries[index].scratched = true;
          this.db.collection('pruebas').doc(`us_${this.prueba.idConcurso}`).collection('pruebas').doc(`us_${this.ipc}`).set({
            cambios: true
          }, { merge: true });
          this.db.collection('vmix_srjt').doc(`${this.prueba.pista}`).collection('results').doc(`us_${idBinomio}`).delete();
          $('#loader').hide();
        } else{
          $('#loader').hide();
          $.NotificationApp.send("Error", response.message, 'bottom-right', '#fa5c7c', 'error');
          console.log(response.message);
        }
      },
      error => {
        $('#loader').hide();
        $.NotificationApp.send("Error", 'It has not been possible to scratch this entry, please try again.', 'bottom-right', '#fa5c7c', 'error');
        console.log(error);
      }
    );
  }
}
