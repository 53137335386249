import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Api } from '../classes/api';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable()
export class VideoService extends Api {

    constructor(private http: HttpClient) {
        super();
    }

    public getConcursos(): Observable<any>{
        const params = JSON.stringify({ accion: 'getConcursos' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-acciones/`, params, {headers: headers});
    }

    public getPruebasConcurso(idConcurso): Observable<any>{
        const params = JSON.stringify({ accion: 'getPruebasConcurso', id_concurso: idConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-acciones/`, params, {headers: headers});
    }

    public getBinomiosPruebasConcurso(idPruebaConcurso): Observable<any>{
        const params = JSON.stringify({ accion: 'getBinomios', id_prueba: idPruebaConcurso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-acciones/`, params, {headers: headers});
    }

    public getResponsablesVideos(filtro): Observable<any>{
        const params = JSON.stringify({ filtro });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/`,params, {headers: headers});
    }

    public setClienteVideo(idBinomio,idPrueba,idCliente,contacto,accion): Observable<any>{
        const params = JSON.stringify({ accion: accion, id_binomio: idBinomio, id_prueba: idPrueba, id_cliente: idCliente, contacto: contacto });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-acciones/`, params, {headers: headers});
    }

    public setPeticionNueva(idBinomio,idPrueba,idCliente,contacto,accion): Observable<any>{
        const params = JSON.stringify({ accion: accion, id_binomio: idBinomio, id_prueba: idPrueba, id_cliente: idCliente, contacto: contacto });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-acciones/`, params, {headers: headers});
    }

    public subirVideo(formData: FormData): Observable<any>{
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'undefined'
        };

        return this.http.post(`${environment.api.uri}videos/upload-video/`, formData, {headers: headers});
    }

    public exclusivoOtomi(binomio,prueba,acceso,accion): Observable<any>{
        const params = JSON.stringify({ accion: accion, id_binomio: binomio, id_prueba: prueba, acceso: acceso });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-acciones/`, params, {headers: headers});
    }

    public habilitarAcceso(prueba,binomio,cliente,contacto,fase,acceso,accion): Observable<any>{
        const params = JSON.stringify({ accion: accion, id_binomio: binomio, id_prueba: prueba, id_cliente: cliente, contacto: contacto, acceso: acceso, fase: fase });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-acciones/`, params, {headers: headers});
    }

    public getPeticionesPendientes(): Observable<any>{
        const params = JSON.stringify({ accion: '' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-pendientes/`,params, {headers: headers});
    }

    public eliminarPeticion(idVideo): Observable<any>{
        const params = JSON.stringify({ id : idVideo });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-pendientes/eliminar-peticion/`,params, {headers: headers});
    }

    public getCuentas(): Observable<any>{
        const params = JSON.stringify({ accion: '' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/cuentas/`,params, {headers: headers});
    }

    public setCuentaNueva(cliente): Observable<any>{
        const params = JSON.stringify(cliente);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/crear-cliente/`,params, {headers: headers});
    }

    public updateCuenta(cliente): Observable<any>{
        const params = JSON.stringify(cliente);
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/actualizar-cliente/`,params, {headers: headers});
    }

    public getPerfilCliente(cliente): Observable<any>{
        const params = JSON.stringify({ id_cliente: cliente });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/perfil-cliente/`,params, {headers: headers});
    }

    public getPaquetes(cliente): Observable<any>{
        const params = JSON.stringify({ id_cliente : cliente, accion: 'getPaquetes' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/perfil-cliente/paquetes-contratados/`,params, {headers: headers});
    }

    public setPaquete(cliente,paquete,metodo,notas): Observable<any>{
        const params = JSON.stringify({ id_cliente : cliente, paquete: paquete, metodo: metodo, notas: notas, accion: 'setPaquete' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/perfil-cliente/paquetes-contratados/`,params, {headers: headers});
    }

    public getVideosPorCuenta(cliente): Observable<any>{
        const params = JSON.stringify({ id_cliente : cliente });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-clientes/perfil-cliente/videos-por-cliente/`,params, {headers: headers});
    }

    public getBalanceVenta(): Observable<any>{
        const params = JSON.stringify({ accion : 'getBalance' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-pagos/`,params, {headers: headers});
    }

    public getPagosSinAsignar(): Observable<any>{
        const params = JSON.stringify({ accion : 'getPagosSinAsignar' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-pagos/`,params, {headers: headers});
    }

    public setCuenta(idCliente,idPago): Observable<any>{
        const params = JSON.stringify({ id_cliente: idCliente, id_pago_paquete_video: idPago, accion : 'setCuenta' });
        const headers = {
            'x-api-key': this.api_key,
            'Content-Type': 'application/json;charset=UTF-8'
        };

        return this.http.post(`${environment.api.uri}videos/videos-pagos/`,params, {headers: headers});
    }
}
