<div id="loader" style="opacity: 1; background: rgba(255, 255, 255, 0.4);">
  <div class="sk-cube-grid ed">
    <div class="sk-cube sk-cube1"></div>
    <div class="sk-cube sk-cube2"></div>
    <div class="sk-cube sk-cube3"></div>
    <div class="sk-cube sk-cube4"></div>
    <div class="sk-cube sk-cube5"></div>
    <div class="sk-cube sk-cube6"></div>
    <div class="sk-cube sk-cube7"></div>
    <div class="sk-cube sk-cube8"></div>
    <div class="sk-cube sk-cube9"></div>
  </div>
</div>

<div class="wrapper">
  <menu-lateral class="left-side-menu"></menu-lateral>
  <div class="content-page">
    <div class="content">
      <menu [concurso]="nombreConcurso" [idConcurso]="idConcurso" class="navbar-custom"></menu>
      <div class="container-fluid" style="width: 100%; float: left; display: flex;">
        <div class="col-12" style="padding:0">
          <div class="col-12" style="margin-top: 30px">
            <div class="card">
              <div class="card-body" style="padding:0">
                <div class="col-12" style="margin-top:30px;">
                  <div class="form-group col-6 float-left">
                    <label>Class</label>
                    <select class="form-control" (change)="getBinomiosPruebasConcurso($any($event).target.value)">
                      <option value="">Select a class</option>
                      <option *ngFor="let prueba of pruebasConcurso" [value]="prueba.ipc">{{ prueba.numero_prueba }} - {{ prueba.nombre }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 float-left">
                  <button type="button" class="btn btn-block btn-xs btn-success" *ngIf="videosPendientes > 0" (click)="getBinomiosPruebasConcurso()">
                    New clips to upload. Click here to refresh.
                  </button>
                </div>
                <div class="col-12 float-left">
                  <div class="widget widget-fullwidth widget-small table-responsive">
                    <table class="table table-fw-widget table-hover table-sm">
                      <thead>
                        <tr>
                          <th>Entry</th>
                          <th>Results</th>
                          <!--th>Cuenta</th>
                          <th>Balance de videos <br> (paq.|env.|saldo) </th-->
                          <th colspan='4' style="text-align:center;">Upload Clip</th>
                          <!--th>Exclusivo <br> Otomí</th-->
                          <th colspan='2'>Hidden</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr #allTheseThings *ngFor="let binomio of binomios_pruebas_concurso; let i = index; let last = last" id="row-{{binomio.id_binomio}}">
                          <!-- COLUMNA 1-->
                          <td>
                            <div style="float:left;">
                              {{ i + 1 }}.-
                            </div>
                            <div style="float:left;">
                              {{ binomio.jinete }} <br>
                              {{ binomio.caballo}}
                            </div>
                            <!--img *ngIf="binomio.suscripcion == 1" style="height: 35px;"
                              src="assets/images/icons/vip.png" alt="petición">
                            <img *ngIf="binomio.suscripcion != 1 && binomio.peticion != null" style="height: 30px;"
                              src="assets/images/icons/money.svg" alt="premium"-->
                          </td>

                          <!---- COLUMNA 2-->
                          <td *ngIf="binomio.calificado_dos == 0 && binomio.status_1 == '0'">
                            {{ convertString(binomio.faltas_1) + convertString(binomio.faltas_tiempo_1) }} | {{ binomio.tiempo_1 }}
                          </td>
                          <td *ngIf="binomio.status_1 != '0' && binomio.calificado_dos == 0 ">
                            {{ binomio.status_1 }}
                          </td>
                          <td *ngIf="binomio.calificado_dos == 1 && binomio.status_1 == '0' && binomio.status_2 == '0'">
                            {{ convertString(binomio.faltas_1) + convertString(binomio.faltas_tiempo_1) }} | {{ binomio.tiempo_1 }} <br />
                            {{ convertString(binomio.faltas_2) + convertString(binomio.faltas_tiempo_2) }} | {{ binomio.tiempo_2 }}
                          </td>
                          <td *ngIf="binomio.calificado_dos == 1 && binomio.status_1 == '0' && binomio.status_2 != '0'">
                            {{ convertString(binomio.faltas_1) + convertString(binomio.faltas_tiempo_1) }} | {{ binomio.tiempo_1 }} <br />
                            {{ binomio.status_2 }}
                          </td>

                          <!-- COLUMNA 3-->
                          <!--td>
                            <input *ngIf="binomio.nombre_cliente != null" value={{binomio.nombre_cliente}} type="text"
                              placeholder="Responsable" class="form-control" [matAutocomplete]="autocompleteCliente"
                              (keyup)="getResponsablesVideos($event)">
                            <input *ngIf="binomio.nombre_cliente == null" type="text" placeholder="Responsable"
                              class="form-control" [matAutocomplete]="autocompleteCliente"
                              (keyup)="getResponsablesVideos($event)"
                              [disabled]="binomio.video_app == '' && binomio.video_app_2 == ''">
                            <mat-autocomplete #autocompleteCliente="matAutocomplete"
                              [displayWith]="displayResponsableCuenta">
                              <mat-option *ngFor="let cliente of clientes" [value]="cliente" class="fila-autocomplete"
                                (click)="setClienteVideo(cliente,binomio,i)">
                                {{ (cliente.nombre_cliente) ? cliente.nombre_cliente : '-' }}
                              </mat-option>
                            </mat-autocomplete>
                          </td-->

                          <!-- COLUMNA 4-->
                          <!--td style="text-align:center;" *ngIf="binomio.saldo > 0 && binomio.nombre_cliente != null"> {{
                            binomio.comprados }} | {{ binomio.enviados }} | <strong style="color:green;"> {{
                              binomio.saldo }} </strong></td>
                          <td style="text-align:center;" *ngIf="binomio.saldo <= 0 && binomio.nombre_cliente != null">
                            {{ binomio.comprados }} | {{ binomio.enviados }} | <strong style="color:red;"> {{
                              binomio.saldo }} </strong></td>
                          <td style="text-align:center;" *ngIf="binomio.nombre_cliente == null"> - | - | - </td-->

                          <!-- COLUMNA 5-->
                          <!-- COLUMNA 5.1-->
                          <td>
                            <!-- <button mat-raised-button class="btn btn-lg btn-rounded btn-danger"
                              (click)="abrirInputFile(binomio.id_binomio)" style="color:black !important;">
                              <img style="height:25px;" src="assets/images/icons/folder.svg" alt="seleccionar archivo">
                              Select. clip
                            </button> -->

                            <!--div (drop)="abrirInputFile(binomio.id_binomio)" accept="video/mp4">Drop Files here</div-->


                          </td>
                          <!-- COLUMNA 5.2-->
                          <td>
                            <div class="dropZone" id="dropZone-{{binomio.id_binomio}}">
                              <input type="file" id="video-{{binomio.id_binomio}}" accept=".mp4" (change)='fileDropped(binomio.id_binomio)'>
                              <img style="height:25px;" src="assets/images/icons/folder.svg" alt="seleccionar archivo">
                              <div id="label-{{binomio.id_binomio}}">Drag and drop file here or browse for file</div>
                            </div>
                          </td>
                          <!-- COLUMNA 5.3-->
                          <td *ngIf="binomio.video_app == ''">
                            <img *ngIf="binomio.cargando_1 == 2 || binomio.cargando_1 == 0" class="float-left" [src]="binomio.cargando_1 == 2 ? 'assets/images/icons/video-player-fine.svg':'assets/images/icons/video-player-bad.svg'" style="height:35px;">
                            <button *ngIf="binomio.cargando_1 == 2 || binomio.cargando_1 == 0" type="button" class="btn btn-rounded" [ngClass]="{'btn-success':binomio.cargando_1 == 2,'btn-warning':binomio.cargando_1 == 0}" (click)="subirVideo(idConcurso,idPruebaConcurso,binomio.id_binomio,1,i)">Upload Clip 1</button>
                            <div *ngIf="binomio.cargando_1 == 1" class="spinner">
                              <div class="rect1"></div>
                              <div class="rect2"></div>
                              <div class="rect3"></div>
                              <div class="rect4"></div>
                              <div class="rect5"></div>
                            </div>
                          </td>

                          <td *ngIf="binomio.video_app != ''">
                            <a class="float-left" href={{binomio.video_app}} target="_blank">
                              <img *ngIf="binomio.cargando_1 == 2 || binomio.cargando_1 == 0" class="float-left"
                                src="assets/images/icons/video-player-fine.svg" style="height:35px;">
                            </a>
                            <button *ngIf="binomio.cargando_1 == 2 || binomio.cargando_1 == 0" type="button" class="btn btn-success btn-rounded" (click)="subirVideo(idConcurso,idPruebaConcurso,binomio.id_binomio,1,i)">re-Upload Clip 1</button>
                            <div class="spinner" *ngIf="binomio.cargando_1 == 1">
                              <div class="rect1"></div>
                              <div class="rect2"></div>
                              <div class="rect3"></div>
                              <div class="rect4"></div>
                              <div class="rect5"></div>
                            </div>
                          </td>

                          <!-- COLUMNA 5.4-->
                          <td *ngIf="binomio.calificado_dos == '1' && binomio.video_app_2 == '' && binomio.modalidad != 'Dos Fases Art. 274.5.3' && binomio.status_2 != 'DNS'">
                            <img *ngIf="binomio.cargando_2 == 2 || binomio.cargando_2 == 0" class="float-left" [src]="binomio.cargando_2 == 2 ? 'assets/images/icons/video-player-fine.svg':'assets/images/icons/video-player-bad.svg'" style="height:35px;">
                            <button *ngIf="binomio.cargando_2 == 2 || binomio.cargando_2 == 0" type="button" class="btn btn-rounded" [ngClass]="{'btn-success':binomio.cargando_2 == 2,'btn-warning':binomio.cargando_2 == 0}" (click)="subirVideo(idConcurso,idPruebaConcurso,binomio.id_binomio,2,i)">Upload Clip 2</button>
                            <div class="spinner" *ngIf="binomio.cargando_2 == 1">
                              <div class="rect1"></div>
                              <div class="rect2"></div>
                              <div class="rect3"></div>
                              <div class="rect4"></div>
                              <div class="rect5"></div>
                            </div>
                          </td>

                          <td *ngIf="binomio.calificado_dos == '1' && binomio.video_app_2 != '' && binomio.status_2 != 'DNS'">
                            <a class="float-left" href={{binomio.video_app}} target="_blank">
                              <img *ngIf="binomio.cargando_2 == 2 || binomio.cargando_2 == 0" class="float-left" src="assets/images/icons/video-player-fine.svg" style="height:35px;">
                            </a>
                            <button *ngIf="binomio.cargando_2 == 2 || binomio.cargando_2 == 0" type="button" class="btn btn-success btn-rounded" (click)="subirVideo(idConcurso,idPruebaConcurso,binomio.id_binomio,2,i)">re-Upload Clip 2</button>
                            <div class="spinner" *ngIf="binomio.cargando_2 == 1">
                              <div class="rect1"></div>
                              <div class="rect2"></div>
                              <div class="rect3"></div>
                              <div class="rect4"></div>
                              <div class="rect5"></div>
                            </div>
                          </td>

                          <td *ngIf="(binomio.calificado_dos == '1' && (binomio.status_2 == 'DNS' || binomio.modalidad == 'Dos Fases Art. 274.5.3')) || binomio.calificado_dos == '0'">
                          </td>

                          <!-- COLUMNA 6-->
                          <!--td *ngIf="binomio.videoOtomi == '1'">
                            <div class="custom-control custom-checkbox">
                              <input id="video-otomi-{{binomio.id_binomio}}" type="checkbox"
                                class="custom-control-input" checked>
                              <label class="custom-control-label" for="video-otomi-{{binomio.id_binomio}}"
                                (click)="exclusivoOtomi(idPruebaConcurso,binomio.id_binomio)"></label>
                            </div>
                          </td-->
                          <!--td *ngIf="binomio.videoOtomi == '0'">
                            <div class="custom-control custom-checkbox">
                              <input id="video-otomi-{{binomio.id_binomio}}" type="checkbox"
                                class="custom-control-input">
                              <label class="custom-control-label" for="video-otomi-{{binomio.id_binomio}}"
                                (click)="exclusivoOtomi(idPruebaConcurso,binomio.id_binomio)"></label>
                            </div>
                          </td-->
                          <!-- COLUMNA 7-->
                          <!-- no tienen ningun video -->

                          <td *ngIf="binomio.video_app == ''"></td>
                          <td *ngIf="binomio.video_app_2 == ''"></td>

                          <td *ngIf="binomio.video_app != ''">
                            <div class="custom-control custom-checkbox">
                              <input id="video-1-{{binomio.id_binomio}}" type="checkbox" class="custom-control-input" [(ngModel)]="binomio.bloqueo1">
                              <label class="custom-control-label" for="video-1-{{binomio.id_binomio}}" (click)="habilitarAcceso( binomio.id_binomio, idPruebaConcurso, 1, 1, binomio.telefono, binomio.email,i)"></label>
                            </div>
                          </td>
                          <td *ngIf="binomio.video_app_2 != ''">
                            <div class="custom-control custom-checkbox">
                              <input id="video-2-{{binomio.id_binomio}}" type="checkbox" class="custom-control-input" [(ngModel)]="binomio.bloqueo2">
                              <label class="custom-control-label" for="video-2-{{binomio.id_binomio}}" (click)="habilitarAcceso( binomio.id_binomio, idPruebaConcurso, 2, 1, binomio.telefono, binomio.email,i)"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
